<template>
    <h3 class="text-lg text-center mb-2">Следующее сообщение</h3>

    <hr />

    <div class="items-center">
        <input
            @input.stop="targetMessage(findme)"
            type="text"
            placeholder="Куда ведет: напиши название..."
            v-model="findme"
            class="block text-sm w-full bg-gray-100 py-2 px-3 mb-4 ring-1 ring-slate-900/10 text-slate-500 rounded shadow-sm"
        />
    </div>

    <div v-if="targetList && targetList.length > 0">
        <div
            v-for="m in targetList.slice(0, 5)"
            :key="m"
            class="bg-origin-border py-2 rounded"
            :class="{ 'bg-sky-200': message.id === m.id }"
            @click.stop="setGoto(m.id)"
        >
            <div class="flex items-center">
                <div class="w-5 h-5 rounded bg-teal-400/50 mr-2 text-white text-center">M</div>
                <div class="mr-2">{{ m.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "GotoList",

    data() {
        return {
            datum: [],
            datumF: [],
            param_id: "",
            findme: "",
        };
    },

    computed: {
        ...mapGetters(["s", "modules", "message", "targetList"]),
    },

    methods: {
        ...mapActions(["closePopup", "setGoto", "targetMessage"]),

        find() {
            this.datumF = this.datum;

            if (this.findme != "" && this.findme != undefined) {
                this.datumF = this.datumF.filter((item) => item.name.toLowerCase().indexOf(this.findme.toLowerCase()) !== -1);
            }
        },
    },
    mounted() {},
};
</script>
