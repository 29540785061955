<template>
    <div v-if="['Main', 'Login', 'Lk'].includes(currentRoute)" class="h-screen w-full overflow-y-auto max-l:grid-cols-1">
        <div class="bg-slate-200 min-h-screen relative overflow-auto max-l:col-start-auto">
            <router-view></router-view>
        </div>
    </div>

    <div v-else class="grid grid-cols-main h-screen w-full overflow-y-auto max-l:grid-cols-1">
        <div class="h-full bg-slate-100">
            <div class="fixed w-16 h-full overflow-hidden mob-max:overflow-y-auto max-l:relative max-l:h-full max-l:w-full max-l:py-2">
                <LeftMenu />
            </div>
        </div>
        <div class="bg-white relative overflow-auto max-l:col-start-auto">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import LeftMenu from "./components/LeftMenu.vue";

export default {
    name: "App",

    components: { LeftMenu },

    computed: {
        ...mapState(useMainStore, ["s", "alert", "routes", "loadData", "closePopup"]),

        currentRoute() {
            return this.$route.name;
        },
    },

    watch: {
        alert() {
            if (this.alert?.type == "success") {
                this.$toast.success(this.alert.msg);
            }
            if (this.alert?.type == "error") {
                this.$toast.error(this.alert.msg);
            }
            if (this.alert?.type == "warning") {
                this.$toast.warning(this.alert.msg);
            }
            if (this.alert?.type == "info") {
                this.$toast.info(this.alert.msg);
            }
        },
    },

    mounted() {
        this.$nextTick(function () {
            this.loadData();
        });
    },
};
</script>

<style>
.mclass {
    position: relative;
    min-height: 100%;
}
.v--default-css .c-toast--success {
    @apply !bg-teal-500;
}
.v--default-css .c-toast--error {
    @apply !bg-rose-500;
}
.v--default-css .c-toast--info {
    @apply !bg-sky-500;
}
</style>
