import { defineStore } from "pinia";
import API from "../services/api";
// import router from "@/services/router";
import { useMainStore as mainStore } from "./main";
import { useBotStore as botStore } from "./bot";

export const useUserStore = defineStore("user", {
    state: () => ({
        usersAll: [],
        users: [],
        user: null,
        history: [],
        vals: [],

        studentsAll: [],
        students: [],

        studentModules: [],
        dzs: [],
        dzsF: [],
    }),

    actions: {
        // студенты для popup в боте
        async getStudentsInBot() {
            const res = await API.GET("bot/student/bot", { id: botStore().bot.id });
            this.studentsAll = res.data.students;
            this.students = res.data.students;
        },

        // Юзеры из всех ботов этого аккаунта
        async getUsers() {
            const res = await API.GET("bot/student/list", mainStore().s);
            console.log(res.data);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.usersAll = res.data.users;
            this.users = res.data.users;
        },

        filterUser() {
            if (!this.usersAll) return;

            let list = this.usersAll;
            let s = mainStore().s;

            if (s.find != "" && s.find != undefined) {
                list = list.filter((item) => {
                    let id = null;
                    let name = null;
                    let secondname = null;
                    id = item.id.toString().indexOf(s.find.toLowerCase()) !== -1;
                    if (item.name) name = item.name.toLowerCase().indexOf(s.find.toLowerCase()) !== -1;
                    if (item.secondname) secondname = item.secondname.toLowerCase().indexOf(s.find.toLowerCase()) !== -1;

                    if (id || name || secondname) return true;
                    return false;
                });
            }

            // if (parseInt(s.bot_id) > 0) list = list.filter((item) => item.students.some((st) => parseInt(st.bot_id) === parseInt(s.bot_id)));

            // if (parseInt(s.status) > 0) list = list.filter((item) => parseInt(item.status) == parseInt(s.status));

            s.clear = true;
            mainStore().setSettings(s);
            this.users = list;
        },

        clearFilterUser() {
            this.users = this.usersAll;
            let s = mainStore().s;
            s.clear = false;
            s.find = null;
            mainStore().setSettings(s);
        },

        async pickUser(user) {
            this.user = user;
            this.history = [];
            const res = await API.GET("bot/student/one", { user_id: user.id });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.user = res.data.user;
            this.vals = res.data.vals;
        },

        // delUser(m) {
        //     const students = this.students.filter((item) => item.id !== m.id);
        //     this.setUsers(students);

        //     const studentsF = this.studentsF.filter((item) => item.id !== m.id);
        //     this.setUsersF(studentsF);

        //     this.setUser(null);
        //     this.setBtns(null);

        //     const formData = new FormData();
        //     formData.append("bot_id", botStore.bot.id);
        //     formData.append("id", m.id);

        //     API.POST("user/delete", formData).then((res) => {
        //         if (res.data.alert) {
        //             // Вам придется определить, как обрабатывать alert в вашем хранилище User
        //         }
        //     });
        // },

        async scheduleOne(data) {
            console.log("getStudentHistory", data);
            let formData = new FormData();
            formData.append("bot_id", data.bot_id);
            formData.append("student_id", data.student_id);

            const res = await API.POST("bot/student/schedule", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.history = res.data.history;
        },

        async getStudentHistory(data) {
            console.log("getStudentHistory", data);
            let formData = new FormData();
            formData.append("bot_id", data.bot_id);
            formData.append("student_id", data.student_id);

            const res = await API.POST("bot/student/history", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.history = res.data.history;
        },

        // async getLk() {
        //     const res = await API.GET("lk", { student_id: this.student_id });
        //     console.log("data", res.data);
        //     this.setStudentModules(res.data.modules);
        //     this.setDzs(res.data.dzs);
        // },

        // viewModuleDz(mod_id) {
        //     console.log("viewModuleDz", mod_id);
        //     const dzsF = this.dzs.filter((item) => item.module_id == mod_id);
        //     this.setDzsF(dzsF);
        // },

        // MINI APP

        async getLk(data) {
            const res = await API.GET("lk", { bot_id: data.bot_id, student_id: data.student_id });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.dzs = res.data.dzs;
        },
    },
});
