<template>
    <div v-for="row in btns" :key="row">
        <div v-for="item in row" :key="item">
            <div v-if="item == btn">
                <div class="flex items-center justify-between mb-4">
                    <h3 class="text-lg text-center">Настройки кнопки</h3>
                    <div class="align-middle self-center place-self-end" @click="delBtn(item), closePopup()">
                        <IcoBig name="trash" :size="5" />
                    </div>
                </div>

                <input
                    type="text"
                    class="block text-sm w-full bg-gray-100 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                    v-model="item[0]"
                    @change="saveBtns(btns)"
                />

                <div class="flex items-center justify-between py-4 mb-4">
                    <div class="rounded-full w-1/8 cursor-pointer aspect-square bg-sky-500" @click.stop="primaryColor(item)"></div>
                    <div class="rounded-full w-1/8 cursor-pointer aspect-square bg-green-500" @click.stop="positiveColor(item)"></div>
                    <div class="rounded-full w-1/8 cursor-pointer aspect-square bg-slate-200" @click.stop="defaultColor(item)"></div>
                    <div class="rounded-full w-1/8 cursor-pointer aspect-square bg-red-500" @click.stop="negativeColor(item)"></div>
                </div>

                <hr />

                <div class="my-4">
                    <div class="text-[9px] text-center mb-2 text-slate-400">{{ item }}</div>
                    <template v-if="item[1] && item[1].length >= 0">
                        <div v-if="item[1].slice(0, 1) == 'n'">
                            <p class="mb-2">Кнопка ведет на</p>
                            <div class="flex items-center justify-between">
                                <div class="text-base cursor-pointer hover:text-sky-600 mr-2" @click="goNext(item[1].slice(1)), closePopup()">
                                    {{ nextName }}
                                </div>
                                <div @click="clearAction(item)">
                                    <IcoBig name="delete" :size="5" />
                                </div>
                            </div>
                        </div>

                        <div v-if="item[1].slice(0, 1) == 'c'">
                            <div class="flex items-center justify-between">
                                <p class="mr-2">Кнопка выполняет программу</p>
                                <div @click="clearAction(item)">
                                    <IcoBig name="delete" :size="5" />
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="items-center">
                            <input
                                @input.stop="targetMessage(findme)"
                                type="text"
                                placeholder="Куда ведет: напиши название..."
                                v-model="findme"
                                class="block text-sm w-full bg-gray-100 py-2 px-3 mb-4 ring-1 ring-slate-900/10 text-slate-500 rounded shadow-sm"
                            />
                        </div>

                        <div v-if="targetList && targetList.length > 0">
                            <div
                                v-for="m in targetList.slice(0, 5)"
                                :key="m"
                                class="bg-origin-border py-2 rounded"
                                :class="{ 'bg-sky-200': message.id === m.id }"
                                @click.stop="addNext({ item: item, id: m.id })"
                            >
                                <div class="flex items-center">
                                    <div class="w-5 h-5 rounded bg-teal-400/50 mr-2 text-white text-center">M</div>
                                    <div class="mr-2">{{ m.name }}</div>
                                    <!-- <div class="text-[9px] text-gray-400">{{ m.sort }}</div> -->
                                </div>
                            </div>
                            <div
                                v-for="p in codeList"
                                :key="p"
                                class="bg-origin-border py-2 rounded"
                                @click.stop="addCode({ item: item, code: p.code })"
                            >
                                <div class="flex items-center">
                                    <div class="w-5 h-5 rounded bg-violet-400/50 mr-2 text-white text-center">></div>
                                    <div class="mr-2">{{ p.name }}</div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- <div class="btn w-full bg-red-500" @click="delBtn(item)">Удалить кнопку</div> -->
                </div>
            </div>
        </div>
    </div>

    <hr />

    <div v-if="message" class="mt-2">
        <p class="mb-2">Прятать после ответа</p>
        <select class="block text-sm w-full bg-gray-100 py-2 px-3 text-slate-500 rounded" v-model="message.clean" @change="saveMessage(message)">
            <option value="0">Нет</option>
            <option value="1">Только кнопки</option>
            <option value="2">Целиком сообщение</option>
        </select>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useMessageStore } from "@/store/message";

export default {
    name: "BtnPopup",

    data() {
        return {
            findme: "",
            datum: [],
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useMessageStore, [
            "messages",
            "message",
            "btns",
            "btn",
            "targetList",
            "codeList",
            "saveBtns",
            "goNext",
            "delBtn",
            "saveMessage",
            "targetMessage",
        ]),

        nextName() {
            if (this.btn && this.btn[1]) {
                let id = this.btn[1].slice(1);
                let next = this.messages.filter((item) => item.id == id);
                if (next[0]?.name) {
                    return next[0].name;
                }
            }
            return "";
        },
    },
    methods: {
        // ...mapActions(["saveBtns", "goNext", "delBtn", "closePopup", "saveMessage", "targetMessage"]),

        primaryColor(item) {
            item[2] = "primary";
            this.btn[2] = "primary";
            this.saveBtns(this.btns);
        },
        positiveColor(item) {
            item[2] = "positive";
            this.btn[2] = "positive";
            this.saveBtns(this.btns);
        },
        defaultColor(item) {
            item[2] = "default";
            this.btn[2] = "default";
            this.saveBtns(this.btns);
        },
        negativeColor(item) {
            item[2] = "negative";
            this.btn[2] = "negative";
            this.saveBtns(this.btns);
        },
        addNext(data) {
            console.log("btn", data.item[1]);
            // console.log("id", data.id);
            // if (data.item[1] && data.item[1].length >= 0) {
            //     console.log("length", data.item[1].length);
            //     data.item[1].push({ n: data.id });
            //     data.item[1].push({ i: 12 });
            // } else {
            //     data.item[1] = { n: data.id };
            // }
            data.item[1] = "n" + data.id;
            this.saveBtns(this.btns);
        },
        addCode(data) {
            data.item[1] = data.code;
            this.saveBtns(this.btns);
        },

        clearAction(item) {
            item[1] = "";
            this.saveBtns(this.btns);
        },
    },
    mounted() {},
};
</script>
