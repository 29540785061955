import { defineStore } from "pinia";
import API from "../services/api";
import axios from "axios";
import router from "@/services/router";
import { useMainStore as mainStore } from "./main";

export const useAuthorStore = defineStore("author", {
    state: () => ({
        menu: {},
        author: {},
    }),

    actions: {
        async login(data) {
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("password", data.password);

            const res = await axios.post("https://yii.botteach.ru/site/login", formData);

            console.log("login", res.data);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            if (!res.data || !res.data.token) return router.push("/login");
            this.author = res.data.author;
            this.menu = res.data.menu;
            if (!this.author || !this.menu) router.push("/login");

            localStorage.setItem("AUTH", res.data.token);
            localStorage.setItem("author", JSON.stringify(this.author));
            localStorage.setItem("menu", JSON.stringify(this.menu));
            return router.push("/bots");
        },

        async registration(data) {
            const res = await axios.post("https://yii.botteach.ru/site/registration", data);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            if (res.data && res.data.token) {
                this.author = res.data.author;
                this.menu = res.data.menu;
                if (!this.author || !this.menu) router.push("/login");
                localStorage.setItem("AUTH", res.data.token);
                localStorage.setItem("author", JSON.stringify(this.author));
                localStorage.setItem("menu", JSON.stringify(this.menu));
                router.push("/bots");
            }
        },

        logout() {
            localStorage.removeItem("AUTH");
            localStorage.removeItem("author");
            localStorage.removeItem("menu");
            this.author = null;
            this.menu = null;
            router.push("/login");
        },

        async getAuthor() {
            if (this.profile?.id && this.menu) return;

            const res = await API.GET("user/user/author");
            if (res.data.author && res.data.author && res.data.menu) {
                this.author = res.data.author;
                this.menu = res.data.menu;
            } else {
                this.menu = null;
                this.author = null;
                // return router.push("/login");
            }
        },

        async changeRole(role) {
            const formData = new FormData();
            formData.append("role", role);
            const res = await API.POST("author/role", formData);
            this.author = res.data.author;
            this.menu = res.data.menu;
        },

        getPermission(url) {
            if (!this.author || !this.author.r || !this.menu) router.push("/login");

            let permission = false;
            this.menu.forEach((item) => {
                if (item.url == url) permission = true;
            });

            if (!permission) router.push(this.menu[0].url);
        },
    },
});
