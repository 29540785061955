<template>
    <div v-if="s.find?.length >= 3" class="latolight w-full text-3xl text-sky-900 tracking-tight mb-8">Результаты поиска {{ messages?.length }}</div>
    <div v-else>
        <sup @click="botInfo()">{{ path }}</sup>
        <input
            v-if="group?.parent > 0"
            type="text"
            class="latolight w-full bg-transparent rounded text-3xl text-sky-900 tracking-tight mb-8 hover:bg-white/20"
            v-model="group.name"
            @change="setGroupName(group?.name)"
        />
        <div v-else class="latolight w-full text-2xl text-sky-900 tracking-tight mb-8">{{ bot.name }}: Сообщения</div>

        <draggable group="{ name: 'g', put: 'm' }" @change="sortGroup" itemKey="id" :list="groups" class="grid grid-cols-4 gap-3 m0--5 mb-10">
            <template #item="{ element }">
                <div
                    v-if="element.status == 99 && group?.parent > 0"
                    :id="'g' + group?.parent"
                    class="mes group cursor-pointer opacity-50 hover:opacity-100 justify-center items-center transition duration-300 hover:scale-105"
                    @click="backGroup(group?.parent)"
                >
                    <IcoBig name="back" :size="8" />
                    <div class="text-xs text-center">{{ element.name }}</div>
                </div>

                <div
                    v-else-if="element.status == 100"
                    class="mes group cursor-pointer opacity-50 hover:opacity-100 justify-center items-center"
                    @click="addGroup(group?.id)"
                >
                    <IcoBig name="plus" :size="8" />
                    <div class="text-xs text-center">{{ element.name }}</div>
                </div>

                <div v-else class="mes grp group cursor-pointer justify-start items-center pt-14" @click="pickGroup(element)" :id="'g' + element.id">
                    <div class="text-xs text-center">{{ element.name }}</div>
                    <span class="absolute top-[22px] transition duration-300 group-hover:text-sky-500 text-lg">{{ element.count }}</span>
                </div>
            </template>
        </draggable>
    </div>

    <template v-if="messagesF?.length > 0">
        <div v-if="s.find?.length < 3" class="latolight w-full text-2xl text-gray-400 tracking-tight mb-8">Сообщения</div>
        <!-- @end="putMessageToGroup($event)" -->
        <draggable group="m" @change="sortMessage()" itemKey="id" :list="messagesF" class="grid grid-cols-4 gap-3 m0--5">
            <template #item="{ element }">
                <div class="relative mesbox" :id="'m' + element.id">
                    <div class="hidden">
                        <div v-if="element.picture" class="img_out">
                            <img :src="'https://yii.botteach.ru/uploads/' + element.bot_id + '/mini/' + element.picture" />
                        </div>
                        <div class="txt_out p10">
                            <h3>{{ element.id }} {{ element?.name }}</h3>
                            <p>{{ element?.m }}</p>
                        </div>
                    </div>

                    <div v-if="element.status != 100" class="btnAdd" @click="addMessage(element)">+</div>

                    <div
                        v-if="element.status == 100"
                        class="mes group cursor-pointer opacity-30 hover:opacity-100 justify-center items-center"
                        @click="addMessage()"
                    >
                        <IcoBig name="plus" :size="8" />
                        <div class="text-xs">{{ element.name }}</div>
                    </div>

                    <div
                        v-else
                        class="mes cursor-pointer bg-origin-border"
                        @click="pickMessage(element)"
                        :class="{
                            '!bg-sky-300': message && message.id === element.id,
                            '!bg-yellow-300': element.id === nextMessageId,
                        }"
                    >
                        <div class="badge"></div>
                        <div v-if="element.btns" class="btns flex justify-center">
                            <template v-for="row in JSON.parse(element.btns)" :key="row">
                                <template v-for="b in row" :key="b">
                                    <div class="xbtn"></div>
                                </template>
                            </template>
                        </div>
                        <div class="text-xs text-center">
                            {{ element.name }}
                        </div>
                    </div>
                </div>
            </template>
        </draggable>
        <div class="grid grid-cols-4 gap-3 m0--5 mt-3">
            <div class="mes group opacity-30 hover:opacity-100 justify-center items-center" @click="setPopup('generate')">
                <IcoBig name="more" :size="8" />
                <div class="text-xs text-center">Генератор</div>
            </div>
        </div>
    </template>

    <template v-else-if="group?.parent > 0">
        <div class="latolight w-full text-2xl text-gray-400 tracking-tight mb-8">Сообщений нет</div>
        <div class="grid grid-cols-4 gap-3 m0--5 mt-3">
            <div class="mes group opacity-30 hover:opacity-100 justify-center items-center" @click="addMessage()">
                <IcoBig name="plus" :size="8" />
                <div class="text-xs">Сообщение</div>
            </div>
            <div class="mes group opacity-30 hover:opacity-100 justify-center items-center" @click="setPopup('generate')">
                <IcoBig name="more" :size="8" />
                <div class="text-xs text-center">Генератор</div>
            </div>
        </div>
    </template>

    <div v-if="group?.parent > 0" class="btn w-fit fixed bottom-4 left-24 bg-red-300/50 hover:bg-red-500" @click="delGroup(group)">
        Удалить группу "{{ group?.name }}"
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";
import { useGroupStore } from "@/store/group";
import { useMessageStore } from "@/store/message";

import draggable from "vuedraggable";
import IcoBig from "../ui/IcoBig.vue";

export default {
    name: "MessageList",

    components: { draggable, IcoBig },

    data() {
        return {
            popup: false,
            btn: null,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useBotStore, ["bot", "botInfo"]),
        ...mapState(useGroupStore, ["groups", "group", "path", "pickGroup", "backGroup", "addGroup", "setGroupName", "sortGroup", "delGroup"]),
        ...mapState(useMessageStore, [
            "messages",
            "messagesF",
            "message",
            "nextMessageId",
            "pickMessage",
            "sortMessage",
            "putMessageToGroup",
            "addMessage",
        ]),
    },

    mounted() {},
};
</script>
