<template>
    <div class="flex h-screen max-h-screen">
        <div class="relative pb-10 w-[440px] bg-violet-100 shadow-lg overflow-y-scroll">
            <div class="sticky top-0 left-0 right-0 p-4 z-50 flex items-center font-semibold bg-violet-800/50 backdrop-blur-sm shadow">
                <div class="relative w-full">
                    <input
                        type="text"
                        class="block text-sm w-full py-2 px-3 text-violet-600 rounded shadow-inner"
                        placeholder="Поиск настройки"
                        v-model="find"
                        @input="findM(find)"
                    />
                    <div v-if="find?.length > 0" class="group absolute top-2 right-2" @click.stop="clearFindM(), (find = null)">
                        <IcoBig name="delete" :size="5" />
                    </div>
                </div>
            </div>

            <div class="h-fit p-5">
                <div class="latolight w-full block text-2xl text-violet-900 tracking-tight mb-8">{{ bot.name }}: Настройки</div>

                <div class="grid grid-cols-3 mb-2">
                    <label>ID telegram</label>
                    <input type="text" class="input col-span-2" v-model="bot.tl" />
                </div>
                <div class="grid grid-cols-3 mb-2">
                    <label>Token</label>
                    <input type="text" class="input col-span-2" v-model="bot.tl_token" />
                </div>
                <div class="grid grid-cols-3 mb-2">
                    <label>Ссылка</label>
                    <input type="text" class="input col-span-2" v-model="bot.tl_link" />
                </div>
                <div class="flex justify-between gap-4">
                    <div v-if="bot.status == 1" class="btn w-fit mt-4 bg-sky-500 hover:bg-sky-400" @click="botInfo(bot)">Подключить бота</div>
                    <div v-if="bot.status == 1" class="btn w-fit mt-4 bg-sky-500 hover:bg-sky-400" @click="saveBot(bot)">Сохранить</div>
                </div>

                <div class="latolight w-full block text-2xl text-violet-900 tracking-tight my-8">{{ bot.name }}: Команда</div>

                <div v-for="item in teams" :key="item" class="py-px w-full group hover:py-1 relative duration-500">
                    <!-- <div class="grid grid-cols-8 items-center gap-2 bg-white/50 py-1 px-2 rounded text-xs cursor-pointer hover:bg-white">
                        <img v-if="item.picture" :src="'https://yii.botteach.ru/users/mini/' + item.picture" class="rounded-full w-8 h-8 shrink-0" />
                        <div v-else class="flex justify-center items-center rounded-full w-8 h-8 shrink-0 bg-slate-200 text-slate-500">
                            {{ item.name?.slice(0, 1) }}
                        </div>
                        <div class="flex items-center text-sm col-span-4 h-8 overflow-hidden leading-5">{{ item.name }} {{ item.secondname }}</div>
                        <div class="">{{ roles[item.role] }}</div>
                        <div class="circle" :class="{ '!bg-teal-500': item.status == 1 }"></div>
                    </div> -->

                    <ItemList :user="item" />
                </div>

                <div class="flex justify-between gap-4">
                    <div class="btn w-fit mt-4 bg-sky-500 hover:bg-sky-400" @click="addTeam()">Добавить</div>
                </div>
            </div>
        </div>

        <div class="flex-1 flex gap-5 p-5 relative">
            <div class="w-2/3">
                {{ teams }}
                <div class="grid grid-cols-4 gap-3 mb-3"></div>
            </div>

            <div v-if="setting" class="w-1/3 relative">
                <div class="grid grid-cols-4 mb-2">
                    <input
                        type="text"
                        class="col-span-3 text-sm bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                        v-model="setting.name"
                        @change="saveSetting(setting)"
                        placeholder="Название"
                    />

                    <div class="align-middle self-center place-self-end" @click="delSetting(setting)">
                        <IcoBig name="trash" :size="5" />
                    </div>

                    <div class="col-span-4 mb-1 text-[10px]" @click="showPopup('segments')">Количество</div>
                    <input
                        type="text"
                        class="col-span-4 mb-4 text-sm bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                        v-model="setting.count"
                        @change="saveSetting(setting)"
                        placeholder="Кол-во"
                    />

                    <div class="col-span-2 mb-4">
                        <div class="mb-1 text-[10px]" @click="showPopup('segments')">Рандом</div>
                        <input
                            type="text"
                            class="w-full text-sm bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                            v-model="setting.random"
                            @change="saveSetting(setting)"
                            placeholder="random"
                        />
                    </div>

                    <div class="col-span-2 mb-4">
                        <div class="mb-1 text-[10px]" @click="showPopup('segments')">Повтор</div>
                        <input
                            type="text"
                            class="w-full text-sm bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                            v-model="setting.replay"
                            @change="saveSetting(setting)"
                            placeholder="replay"
                        />
                    </div>

                    <div class="col-span-4 mb-1 text-[10px]" @click="showPopup('segments')">Время</div>
                    <input
                        type="time"
                        class="col-span-4 mb-4 text-sm bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                        v-model="setting.time"
                        @change="saveSetting(setting)"
                        placeholder="time"
                    />

                    <div class="col-span-4 mb-1 text-[10px]" @click="showPopup('segments')">Статус</div>
                    <input
                        type="text"
                        class="col-span-4 mb-4 text-sm bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                        v-model="setting.status"
                        @change="saveSetting(setting)"
                        placeholder="status"
                    />
                </div>
            </div>
        </div>

        <div v-if="s.popup" @click="closePopup()" class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-50" id="overlay"></div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";

import ItemList from "../User/components/ItemList.vue";
import IcoBig from "../components/ui/IcoBig.vue";

export default {
    name: "SettingList",

    components: { IcoBig, ItemList },

    data() {
        return {
            find: "",
            popup: false,
            btn: null,
            next_id: 0,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "roles", "setPopup", "closePopup", "setSettings", "setView"]),
        ...mapState(useBotStore, ["bot", "teams", "getBot", "saveBot"]),
    },

    mounted() {
        this.getBot();
    },
};
</script>
