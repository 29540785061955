<template>
    <div class="flex h-screen max-h-screen max-l:block">
        <div class="relative pb-10 w-[440px] bg-yellow-100 overflow-y-scroll max-l:h-100 max-l:w-full max-l:overflow-hidden">
            <div class="sticky top-0 left-0 right-0 p-4 z-50 flex items-center font-semibold bg-yellow-800/50 backdrop-blur-sm shadow">
                <div class="relative w-full">
                    <input
                        type="text"
                        class="block text-sm w-full py-2 px-3 text-yellow-600 rounded shadow-inner"
                        placeholder="Поиск сообщения"
                        v-model="s.find"
                        @input="findM(s.find)"
                    />
                    <div v-if="s.find?.length > 0" class="group absolute top-2 right-2" @click.stop="clearFindM(), (s.find = null)">
                        <IcoBig name="delete" :size="5" />
                    </div>
                </div>
            </div>

            <div v-if="timers?.length > 0">
                <div class="h-fit p-5">
                    <div class="latolight w-full block text-2xl text-yellow-900 tracking-tight mb-8">{{ bot.name }}: Таймеры</div>
                    <draggable group="m" @change="sortTimer()" itemKey="id" :list="timers" class="grid grid-cols-4 gap-3 m0--5">
                        <template #item="{ element }">
                            <div class="relative mesbox" :id="'m' + element.id">
                                <div
                                    v-if="element.status == 100"
                                    class="mes group opacity-30 hover:opacity-100 justify-center items-center"
                                    @click="addTimer()"
                                >
                                    <IcoBig name="plus" :size="8" />
                                    <div class="text-xs">{{ element.name }}</div>
                                </div>

                                <div
                                    v-else
                                    class="mes bg-origin-border"
                                    @click="pickTimer(element)"
                                    :class="{ '!bg-cyan-200': timer && timer.id === element.id, '!opacity-50': element.status == 0 }"
                                >
                                    <div class="text-xs text-center">
                                        {{ element.count }}
                                    </div>
                                    <div class="text-xs text-center">
                                        {{ element.name }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </draggable>
                </div>
            </div>
            <template v-else>
                <div class="h-fit p-5">
                    <div class="latolight w-full block text-2xl text-yellow-900 tracking-tight mb-8">{{ bot.name }}: Таймеры</div>
                    <div class="grid grid-cols-4 gap-3 m0--5 mt-3">
                        <div class="mes group opacity-30 hover:opacity-100 justify-center items-center" @click="addTimer()">
                            <IcoBig name="plus" :size="8" />
                            <div class="text-xs">Рассылка</div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div v-if="timer" class="flex-1 flex gap-5 p-5 relative">
            <div class="w-2/3">
                <div class="grid grid-cols-4 gap-3 mb-3"></div>
            </div>

            <div class="w-1/3 relative">
                <div class="grid grid-cols-4 mb-2">
                    <input
                        type="text"
                        class="col-span-3 text-sm bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                        v-model="timer.name"
                        @change="saveTimer(timer)"
                        placeholder="Название"
                    />

                    <div class="align-middle self-center place-self-end" @click="delTimer(timer)">
                        <IcoBig name="trash" :size="5" />
                    </div>

                    <div class="py-2 my-2 col-span-2 hover:bg-cyan-50 rounded-lg group">
                        <div class="text-[10px] text-slate-400" @click="setPopup('segments')">Сегменты</div>
                        <div v-if="!timer.theme_id || timer.theme_id <= 0" class="h-6" @click="setPopup('segments')">Не выбрано</div>
                        <template v-for="segment in segments" :key="segment">
                            <div v-if="segment.id == timer.theme_id" class="h-6 text-base" @click="setPopup('segments')">
                                {{ segment.name }}
                            </div>
                        </template>
                    </div>

                    <div class="py-2 my-2 col-span-2 hover:bg-cyan-50 rounded-lg group">
                        <div class="text-[10px] text-slate-400" @click="setPopup('groups')">Группа</div>
                        <div v-if="!timer.group_id" class="h-6" @click="setPopup('groups')">Не выбрано</div>

                        <div v-else class="flex justify-between">
                            <template v-for="group in groups" :key="group">
                                <div v-if="group.id == timer.group_id" class="text-base" @click="setPopup('groups')">
                                    {{ group.name }}
                                </div>
                            </template>
                            <div class="opacity-0 group-hover:opacity-100" @click.stop="setTimerParam({ type: 'groups', param_id: 0 })">
                                <IcoBig name="delete" :size="5" />
                            </div>
                        </div>
                    </div>

                    <div class="py-2 my-2 col-span-2 hover:bg-cyan-50 rounded-lg group">
                        <div class="text-[10px] text-slate-400" @click="setPopup('types')">Тип</div>
                        <div v-if="!timer.type_id || timer.type_id <= 0" class="h-6" @click="setPopup('types')">Не выбрано</div>

                        <div v-else class="flex justify-between">
                            <template v-for="type in types" :key="type">
                                <div v-if="type.id == timer.type_id" class="text-base" @click="setPopup('types')">{{ type.name }}</div>
                            </template>
                            <div class="opacity-0 group-hover:opacity-100" @click.stop="setTimerParam({ type: 'types', param_id: 0 })">
                                <IcoBig name="delete" :size="5" />
                            </div>
                        </div>

                        <!-- <template v-for="type in types" :key="type">
                            <div v-if="type.id == timer.type_id" class="h-6 text-base" @click="setPopup('types')">
                                {{ type.name }}
                            </div>
                        </template> -->
                    </div>

                    <div class="py-2 my-2 col-span-2 hover:bg-cyan-50 rounded-lg group">
                        <div class="text-[10px] text-slate-400" @click="setPopup('modules')">Модуль</div>
                        <div v-if="!timer.module_id || timer.module_id <= 0" class="h-6" @click="setPopup('modules')">Не выбрано</div>
                        <div v-else class="flex justify-between">
                            <template v-for="mod in modules" :key="mod">
                                <div v-if="mod.id == timer.module_id" class="h-6 text-base" @click="setPopup('modules')">
                                    {{ mod.name }}
                                </div>
                            </template>
                            <div class="opacity-0 group-hover:opacity-100" @click.stop="setTimerParam({ type: 'modules', param_id: 0 })">
                                <IcoBig name="delete" :size="5" />
                            </div>
                        </div>
                    </div>

                    <div class="flex col-span-4 mb-4 gap-4">
                        <div class="w-1/2">
                            <div class="w-full mb-1 text-[10px] text-slate-400">Количество</div>
                            <input
                                type="text"
                                class="w-full mb-4 text-sm bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                                v-model="timer.count"
                                @change="saveTimer(timer)"
                                placeholder="Кол-во"
                            />
                        </div>

                        <div class="w-1/2">
                            <div class="w-full mb-1 text-[10px] text-slate-400">Время</div>
                            <input
                                type="time"
                                class="w-full mb-4 text-sm bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                                v-model="timer.time"
                                @change="saveTimer(timer)"
                                placeholder="time"
                            />
                        </div>
                    </div>

                    <div class="flex col-span-4 mb-4 gap-4">
                        <div class="w-1/2">
                            <div
                                class="flex col-span-4 justify-start items-center group p-1 text-[10px] text-slate-400 cursor-pointer"
                                @click="changeRandom()"
                            >
                                Рандом
                                <input type="checkbox" for="random" :checked="timer.random" class="peer appearance-none rounded-md cursor-pointer" />
                                <span
                                    class="w-8 h-5 flex items-center flex-shrink-0 ml-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-teal-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
                                ></span>
                            </div>
                        </div>

                        <div class="w-1/2">
                            <div
                                class="flex col-span-4 justify-start items-center group p-1 text-[10px] text-slate-400 cursor-pointer"
                                @click="changeReplay()"
                            >
                                Повтор
                                <input type="checkbox" for="replay" :checked="timer.replay" class="peer appearance-none rounded-md cursor-pointer" />
                                <span
                                    class="w-8 h-5 flex items-center flex-shrink-0 ml-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-teal-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
                                ></span>
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex col-span-4 justify-start items-center group p-1 text-[10px] text-slate-400 cursor-pointer"
                        @click="changeStatus()"
                    >
                        Статус
                        <input type="checkbox" for="status" :checked="timer.status" class="peer appearance-none rounded-md cursor-pointer" />
                        <span
                            class="w-8 h-5 flex items-center flex-shrink-0 ml-4 p-[2px] bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-teal-400 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3 group-hover:after:translate-x-[2px]"
                        ></span>
                    </div>

                    <!-- <div class="col-span-4 mb-1 text-[10px] text-slate-400">Статус</div>
                    <input
                        type="text"
                        class="col-span-4 mb-4 text-sm bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                        v-model="timer.status"
                        @change="saveTimer(timer)"
                        placeholder="status"
                    /> -->
                </div>
            </div>
        </div>

        <div
            v-if="['segments', 'groups', 'types', 'modules'].includes(s.popup)"
            class="absolute top-20 right-20 max-h-2/3 h-fit w-[300px] rounded z-50 px-8 py-4 bg-white overflow-hidden"
        >
            <TimerParams />
        </div>

        <div v-if="s.popup" @click="closePopup()" class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-50" id="overlay"></div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";
import { useGroupStore } from "@/store/group";
import { useMessageStore } from "@/store/message";
import { useTimerStore } from "@/store/timer";
import moment from "moment";

import draggable from "vuedraggable";
import "@vuepic/vue-datepicker/dist/main.css";
import IcoBig from "../components/ui/IcoBig.vue";
import TimerParams from "../components/Timer/TimerParams.vue";

export default {
    name: "TimerList",

    components: { draggable, IcoBig, TimerParams },

    data() {
        return { moment: moment };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setSettings", "setPopup", "closePopup", "toggleListView"]),
        ...mapState(useBotStore, ["bots", "bot", "getBot"]),
        ...mapState(useGroupStore, ["groups"]),
        ...mapState(useMessageStore, ["types", "modules", "variables", "themes", "segments"]),
        ...mapState(useTimerStore, [
            "timers",
            "timer",
            "history",
            "getTimers",
            "filterTimer",
            "pickTimer",
            "addTimer",
            "saveTimer",
            "sortTimer",
            "delTimer",
            "setTimerParam",
        ]),

        // ...mapGetters(["s", "bot", "segments",  "types", "modules", "timers", "timer"]),
    },

    methods: {
        // ...mapActions(["getTimers",  "findMs",]),

        changeRandom() {
            if (this.timer.random == 1 || this.timer.random == true) {
                this.timer.random = 0;
            } else {
                this.timer.random = 1;
            }
            this.saveTimer(this.timer);
        },

        changeReplay() {
            if (this.timer.replay == 1 || this.timer.replay == true) {
                this.timer.replay = 0;
            } else {
                this.timer.replay = 1;
            }
            this.saveTimer(this.timer);
        },

        changeStatus() {
            if (this.timer.status == 1 || this.timer.status == true) {
                this.timer.status = 0;
            } else {
                this.timer.status = 1;
            }
            this.saveTimer(this.timer);
        },
    },

    mounted() {
        this.getBot();
        this.getTimers();
    },
};
</script>
