import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";
import { useBotStore as botStore } from "./bot";

export const useAdStore = defineStore("ad", {
    state: () => ({
        utms: [],
    }),

    actions: {
        async getUtms() {
            const res = await API.GET("ad/utm/list", { id: botStore().bot.id });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.utms = res.data.utms;
        },
    },
});
