<template>
    <div class="w-2/3 max-l:w-full">
        <div class="grid grid-cols-4 gap-3 mb-3">
            <input
                type="text"
                class="block col-span-2 text-sm ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                v-model="message.name"
                @change="saveMessage(message)"
                placeholder="Название (техническое)"
            />
            <div class="flex items-center">
                <div class="mr-4" @click="sendMe('tl')"><IcoBig name="tl" :size="8" /></div>
                <!-- <div class="mr-4" @click="sendMe('vk')"><IcoBig name="vk" :size="8" /></div> -->
                <div class="mr-4" @click="setPopup('students')"><IcoBig name="woman" :size="8" /></div>
            </div>
            <div class="align-middle self-center place-self-end" @click="delMessage(message)"><IcoBig name="trash" :size="5" /></div>
        </div>

        <template v-if="message.type_id == 32"><sup>ВОПРОС</sup></template>

        <textarea
            type="text"
            rows="10"
            class="block text-sm w-full p-3 mb-3 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
            v-model="message.message"
            @change="saveMessage(message)"
        >
        </textarea>

        <template v-if="[30, 31].includes(message.type_id)">
            <input
                type="text"
                class="block text-sm w-full p-3 mb-3 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                v-model="message.answ_id"
                @change="saveMessage(message)"
                placeholder="Правильный ответ"
            />

            <div class="grid grid-cols-2 gap-4">
                <textarea
                    type="text"
                    rows="5"
                    class="block text-sm w-full p-3 mb-3 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                    v-model="message.answy"
                    @change="saveMessage(message)"
                >
                </textarea>

                <textarea
                    type="text"
                    rows="5"
                    class="block text-sm w-full p-3 mb-3 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                    v-model="message.answn"
                    @change="saveMessage(message)"
                >
                </textarea>
            </div>
        </template>

        <!-- без вариантов ответа -->
        <template v-if="message.type_id == 32">
            <sup>ОТВЕТ</sup>
            <textarea
                type="text"
                rows="5"
                class="block text-sm w-full p-3 mb-3 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                v-model="message.answy"
                @change="saveMessage(message)"
            >
            </textarea>
        </template>

        <!-- <input
                    type="text"
                    class="block text-xs w-full disabled:opacity-50 bg-gray-200 ring-1 ring-slate-900/10 mb-2 py-2 px-3 text-slate-500 rounded"
                    disabled
                    v-model="message.btns"
                    @change="saveMessage(message)"
                    placeholder="Кнопки"
                /> -->

        <div v-if="message.type_id != 32" class="relative btnbox mb-10">
            <template v-if="btns">
                <div v-for="(row, idx) in btns" :key="row" class="relative">
                    <draggable class="flex rounded-md bg-gray-100 min-h-[40px] mb-2 p-1.5" :list="row" group="b" @change="sortBtn" itemKey="name">
                        <template #item="{ element }">
                            <div
                                class="sbtn bg-sky-500 m-1"
                                @click="pickBtn(element)"
                                @mouseover="hoverNext(element[1].slice(1))"
                                @mouseleave="hoverNext()"
                                :class="{
                                    '!bg-green-500': element[2] == 'positive',
                                    '!bg-white !text-gray-600': element[2] == 'default',
                                    '!bg-red-500': element[2] == 'negative',
                                }"
                            >
                                {{ element[0] }}
                            </div>
                        </template>
                    </draggable>

                    <div v-if="row?.length < 4" class="absolute text-gray-400 cursor-pointer top-2 right-1 group" @click="addBtn(idx)">
                        <IcoBig name="plus" :size="8" />
                    </div>
                </div>
            </template>

            <div v-if="!btns || btns?.length < 5" class="text-gray-400 cursor-pointer top-2 right-1 group" @click="addBtn(btns?.length)">
                <div class="flex rounded-md bg-gray-100 min-h-[40px] mb-2 p-1.5">
                    <IcoBig name="plus" :size="8" />
                </div>
            </div>
        </div>

        <div v-if="message?.file?.name" class="relative group hover:bg-sky-50">
            <img :src="'https://yii.botteach.ru/uploads/' + bot.id + '/mini/' + message.file.name" alt="" class="h-28 rounded-lg object-cover" />
            {{ message.file.description }}
            <div class="absolute top-0 right-0" @click.stop="delImage()"><IcoBig name="delete" :size="5" /></div>
        </div>

        <!-- ЗАГРУЗКА -->
        <div v-else class="grid grid-cols-3 gap-3 mb-4">
            <div
                @click="setPopup('files')"
                class="flex p-3 rounded text-gray-400 border border-dashed border-gray-300 cursor-pointer items-center justify-start hover:text-sky-500 hover:border-sky-500 group"
            >
                <IcoBig name="image" :size="5" />
                <div class="text-base ml-3">Картинка</div>
            </div>

            <div
                @click="setPopup('files')"
                class="flex p-3 rounded text-gray-400 border border-dashed border-gray-300 cursor-pointer items-center justify-start hover:text-sky-500 hover:border-sky-500 group"
            >
                <IcoBig name="audio" :size="5" />
                <div class="text-base ml-3">Аудио</div>
            </div>

            <div
                @click="setPopup('files')"
                class="flex p-3 rounded text-gray-400 border border-dashed border-gray-300 cursor-pointer items-center justify-start hover:text-sky-500 hover:border-sky-500 group"
            >
                <IcoBig name="video" :size="5" />
                <div class="text-base ml-3">Видео</div>
            </div>
            <!-- <div class="w-full text-black jsgen">СГЕНЕРИРОВАТЬ ФОТО</div> -->
        </div>

        <!-- СГЕНЕРИРОВАТЬ ИЗ TXT -->
        <!-- <div class="mb-4" @click="generateFromTxt({ filename: 'microservice.txt', module_id: 34, theme_id: 0 })">
            <div class="text-base ml-3 bg-sky-500 text-white p-2 rounded text-center">СГЕНЕРИРОВАТЬ ИЗ TXT</div>
        </div> -->
    </div>

    <div class="w-1/3 max-l:w-full relative">
        <!-- <div class="round blt mb10 p15">
                    <div class="flex mt15">
                        <div class="mr30">{{ message.id }}</div>
                        <div class="mr30">{{ message.mid }}</div>
                        <div class="jssend mr30" soc="1" uid="2">
                            <img
                                src="http://bot.local/i/i_vk.png"
                                class="ico"
                            />
                        </div>
                        <div class="jssend mr30" soc="2" uid="2">
                            <img
                                src="http://bot.local/i/i_tl.png"
                                class="ico"
                            />
                        </div>
                    </div>
                </div> -->

        <!-- <h3 class="text-lg">Настройки</h3> -->
        <div class="grid grid-cols-2 mb-2">
            <!-- <div>
                        <div>Группа</div>
                        <div>
                            <input
                                type="text"
                                class="block text-sm w-full bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                                v-model="message.group_id"
                            />
                        </div>
                    </div> -->

            <div class="p-2 my-2 hover:bg-sky-50 rounded-lg">
                <div class="text-[10px]" @click="setPopup('types')">Тип</div>
                <template v-for="type in types" :key="type">
                    <div v-if="type.id == message.type_id" class="text-base" @click="setPopup('types')">
                        {{ type.name }}
                    </div>
                </template>
            </div>

            <div class="p-2 my-2 hover:bg-sky-50 rounded-lg">
                <div class="text-[10px]" @click="setPopup('modules')">Модуль</div>
                <div v-if="!message?.module_id || message?.module_id <= 0" @click="setPopup('modules')">Не выбрано</div>
                <div v-else class="flex justify-between">
                    <template v-for="mod in modules" :key="mod">
                        <div v-if="mod.id == message.module_id" class="text-base" @click="setPopup('modules')">
                            {{ mod.name }}
                        </div>
                    </template>
                    <div class="group" @click.stop="clearParam('module_id')">
                        <IcoBig name="delete" :size="5" />
                    </div>
                </div>
            </div>

            <div class="p-2 my-2 hover:bg-sky-50 rounded-lg">
                <div class="text-[10px]" @click="setPopup('variables')">Сохранить ответ</div>
                <div v-if="!message?.variable_id || message?.variable_id <= 0" @click="setPopup('variables')">Не выбрано</div>
                <div v-else class="flex justify-between">
                    <template v-for="v in variables" :key="v">
                        <div v-if="v.id == message.variable_id" class="text-base" @click="setPopup('variables')">
                            {{ v.name }}
                        </div>
                    </template>
                    <div class="group" @click.stop="clearParam('variable_id')">
                        <IcoBig name="delete" :size="5" />
                    </div>
                </div>
            </div>

            <div class="p-2 my-2 hover:bg-sky-50 rounded-lg">
                <div class="text-[10px]" @click="setPopup('themes')">Тема</div>
                <div v-if="!message?.theme_id || message?.theme_id <= 0" @click="setPopup('themes')">Не выбрано</div>
                <div v-else class="flex justify-between">
                    <template v-for="theme in themes" :key="theme">
                        <div v-if="theme.id == message.theme_id" class="text-base" @click="setPopup('themes')">
                            {{ theme.name }}
                        </div>
                    </template>
                    <div class="group" @click.stop="clearParam('theme_id')">
                        <IcoBig name="delete" :size="5" />
                    </div>
                </div>
            </div>

            <div class="p-2 my-2 hover:bg-sky-50 rounded-lg">
                <div class="text-[10px]" @click="setPopup('segments')">Сегменты</div>
                <div v-if="!message?.segments || message?.segments <= 0" @click="setPopup('segments')">Не выбрано</div>
                <template v-for="segment in segments" :key="segment">
                    <div v-if="segment.id == message.segments" class="text-base" @click="setPopup('segments')">
                        {{ segment.name }}
                    </div>
                </template>
            </div>

            <div class="p-2 my-2 hover:bg-sky-50 rounded-lg">
                <div class="text-[10px]" @click="setPopup('life')">Награда</div>
                <div class="flex justify-between" @click="setPopup('life')">
                    <div class="text-base">{{ message.life }}</div>
                    <div class="text-base">{{ message.energy }}</div>
                    <div class="text-base">{{ message.pointy }}</div>
                    <div class="text-base">{{ message.day }}</div>
                </div>
            </div>

            <div class="p-2 my-2 hover:bg-sky-50 rounded-lg">
                <div class="text-[10px]" @click="setPopup('goto')">Следующее</div>
                <div class="flex justify-between" @click="setPopup('goto')">
                    <div class="text-base">{{ message.goto }}</div>
                </div>
            </div>

            <!-- <div class="col">
                        <div>Ждем</div>
                        <input
                            type="text"
                            class="block text-sm w-full bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                            name="delay"
                        />
                    </div> -->

            <!-- <div class="col">
                        <div>Куда</div>
                        <input
                            type="text"
                            class="block text-sm w-full bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                            name="goto"
                        />
                    </div> -->

            <!-- <div class="col">
                        <div>Код</div>
                        <input
                            type="text"
                            class="block text-sm w-full bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                            name="cod"
                        />
                    </div> -->

            <!-- <div class="col">
                        <div>Ж Э Б Д</div>
                        <input
                            type="text"
                            class="block text-sm w-full bg-gray-200 ring-1 ring-slate-900/10 py-2 px-3 text-slate-500 rounded"
                            name="pay"
                            value=""
                        />
                    </div> -->

            <!-- <div>
                        <div>Дата</div>
                        <Datepicker class="block text-sm w-full bg-gray-200 ring-1 ring-slate-900/10 text-slate-500 rounded" v-model="message.date">
                        </Datepicker>
                    </div> -->
        </div>

        <!-- <div class="flex">
                    🤖 — ❗ — 😃 — 🤐😋😃🤩😅🤣😘😉😊😢😬😨🤑😭😞😕🤝🏻👌🏻💪🏻🙏🏻👉🏻✋🏻👏🏻👍🏻👍✊🏻👆🏻 🆘⛔⛩🔥🌈🌀⭐🎯🏆🥇🍕💭👽😈💭🔊📢❗🎁✏📅🚀💯✅⭕️
                    <br />
                    [gen]она|он[/gen]
                </div> -->

        <!-- <div v-if="popup == 'img'" id="popup" class="h-screen w-full px-4"><h2>Сгенерировать картинку</h2></div> -->
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";
import { useGroupStore } from "@/store/group";
import { useMessageStore } from "@/store/message";

import draggable from "vuedraggable";
import IcoBig from "../ui/IcoBig.vue";

export default {
    name: "MessageEdit",

    components: {
        draggable,
        IcoBig,
    },

    data() {
        return {
            popup: false,
            btn: null,
            next_id: 0,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useBotStore, ["bot"]),
        ...mapState(useGroupStore, ["groups", "group", "path"]),
        ...mapState(useMessageStore, [
            "message",
            "btns",
            "types",
            "modules",
            "variables",
            "themes",
            "segments",
            "saveMessage",
            "delMessage",
            "clearParam",
            "addBtn",
            "pickBtn",
            "hoverNext",
            "saveBtns",
            "delBtn",
            "generateFromTxt",
            "sendMe",
        ]),
    },

    methods: {
        // ...mapActions([
        //     "saveMessage",
        //     "delMessage",
        //     "clearParam",
        //     "addBtn",
        //     "pickBtn",
        //     "hoverNext",
        //     "saveBtns",
        //     "delBtn",
        //     "setPopup",
        //     "generateFromTxt",
        //     "sendMe",
        // ]),

        sortBtn() {
            this.saveBtns(this.btns);
        },

        delImage() {
            this.message.file_id = null;
            this.message.file = null;
            this.saveMessage(this.message);
        },
    },
    mounted() {},
};
</script>
