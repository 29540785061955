import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";
import { useBotStore as botStore } from "./bot";
import { useMessageStore as messageStore } from "./message";

export const useGroupStore = defineStore("group", {
    state: () => ({
        groupsAll: [],
        groups: [],
        group: null,
        path: "",
    }),

    actions: {
        loadPath() {
            let path = localStorage.getItem("path");
            this.path = path ? path : "Бот";
        },

        loadGroup() {
            let groups = [];
            let gid = parseInt(localStorage.getItem("gid"));
            if (gid && gid !== "undefined") {
                groups = this.groupsAll.filter((item) => item.id === parseInt(gid));
            } else {
                groups = this.groupsAll.filter((item) => item.parent === 0);
            }
            this.group = groups[0];
        },

        setGroups(gs) {
            if (this.group && this.group.parent > 0) {
                gs.unshift({ status: 99, name: "назад" });
            }
            gs.push({ status: 100, name: "папка" });
            this.groups = gs;
        },

        generatePath() {
            return "Бот";

            // function prevStep(group, path) {
            //     if (group.parent === 0) {
            //         return "Бот" + path;
            //     } else {
            //         path = "/" + group.name + path;
            //         let g = this.groupsAll.filter((item) => item.id == group.parent);
            //         return prevStep(g[0], path);
            //     }
            // }

            // this.path = prevStep(this.group, "");
            // if (prevStep(this.group, "")) {
            //     localStorage.setItem("path", prevStep(this.group, ""));
            // } else {
            //     localStorage.removeItem("path");
            // }
        },

        // в группу
        pickGroup(group) {
            this.group = group;
            localStorage.setItem("gid", group.id);

            messageStore().filterMessages(group.id); // отфильтровать сообщения

            // вложенные группы
            this.setGroups(this.groupsAll.filter((item) => item.parent == group.id));
            this.generatePath();
        },

        async addGroup(parent) {
            let formData = new FormData();
            formData.append("bot_id", botStore().bot.id);
            formData.append("parent", parent);

            const res = await API.POST("message/group/add", formData);
            this.groupsAll = res.data.groups;
            this.pickGroup(res.data.group);
        },

        increment() {
            this.group.count += 1;
        },

        decrement() {
            this.group.count -= 1;
        },

        // назад
        backGroup(id) {
            let group = this.groupsAll.filter((item) => item.id === id);
            this.pickGroup(group[0]);
            this.generatePath();
        },

        async setGroupName(name) {
            let formData = new FormData();
            formData.append("bot_id", botStore().bot.id);
            formData.append("group_id", this.group.id);
            formData.append("name", name);

            const res = await API.POST("message/group/set-name", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.groupsAll = res.data.groups;
        },

        async sortGroup() {
            let sort = [];
            this.groups.forEach((g) => {
                if (g.id && g.id > 0) {
                    return sort.push(g.id);
                }
            });

            let formData = new FormData();
            formData.append("parent", this.group.id);
            formData.append("sort", sort);

            const res = await API.POST("message/group/sort", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
        },

        async delGroup(g) {
            let formData = new FormData();
            formData.append("group_id", g.id);

            this.groupsAll = this.groupsAll.filter((item) => item != g);

            let groups = this.groups.filter((item) => item != g);
            this.setGroupsF(groups);

            let parent = this.groupsAll.filter((item) => item.id == g.parent);
            this.pickGroup(parent[0]);

            const res = await API.POST("message/group/delete", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
        },
    },
});
