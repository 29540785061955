import API from "../../services/api";
import router from "@/services/router";

export default {
    state: {
        users: [],
        usersF: [],
        studentModules: [],
        dzs: [],
        dzsF: [],
        user: null,
    },

    mutations: {
        setUsers: (state, users) => {
            state.users = users;
            state.usersF = users;
        },
        setUsersF: (state, users) => {
            state.usersF = users;
        },
        setUser: (state, user) => {
            state.user = user;
            localStorage.setItem("user", JSON.stringify(user));
        },
        setStudentHistory: (state, history) => {
            state.history = history;
        },
        setStudentModules: (state, studentModules) => {
            state.studentModules = studentModules;
        },
        setDzs: (state, dzs) => {
            state.dzs = dzs;
            state.dzsF = dzs;
        },
        setDzsF: (state, dzsF) => {
            state.dzsF = dzsF;
        },
    },

    actions: {
        getUsers(ctx) {
            ctx.dispatch("loadBot");
            if (!ctx.rootState.bot.bot.id) {
                router.push("/bot");
            }

            API.GET("api/user/all", { bot_id: ctx.rootState.bot.bot.id }).then((res) => {
                console.log("data", res.data);
                localStorage.setItem("users", JSON.stringify(res.data.users));
                ctx.commit("setUsers", res.data.users);
                ctx.dispatch("showAll");
            });
        },

        // покажем выбранное (localStorage) после перезагрузки
        showAll(ctx) {
            // отфильтруем по боту
            // let users = ctx.state.users.filter((item) => item.group_id == ctx.rootState.group.group.id);
            // ctx.commit("setUsersF", users);

            // покажем выбранное сообщение
            let user = JSON.parse(localStorage.getItem("user"));
            if (user) {
                ctx.commit("setUser", user);
            }
        },

        delUser(ctx, m) {
            let ms = ctx.state.users.filter((item) => item.id !== m.id);
            ctx.commit("setUsers", ms);

            let msF = ctx.state.usersF.filter((item) => item.id !== m.id);
            ctx.commit("setUsersF", msF);

            ctx.commit("setUser", null);
            ctx.commit("setBtns", null);

            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("id", m.id);

            API.POST("user/delete", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },

        getStudentHistory(ctx, data) {
            API.GET("api/student/history", { bot_id: data.bot_id, student_id: data.student_id }).then((res) => {
                console.log("data", res.data);
                ctx.commit("setStudentHistory", res.data.history);
            });
        },

        getLk(ctx) {
            API.GET("lk", { student_id: ctx.state.student_id }).then((res) => {
                console.log("data", res.data);
                ctx.commit("setStudentModules", res.data.modules);
                ctx.commit("setDzs", res.data.dzs);
            });
        },

        viewModuleDz(ctx, mod_id) {
            console.log("viewModuleDz", mod_id);
            let dzsF = ctx.state.dzs.filter((item) => item.module_id == mod_id);
            ctx.commit("setDzsF", dzsF);
        },
    },

    getters: {
        users(state) {
            return state.usersF;
        },
        user(state) {
            return state.user;
        },
        history(state) {
            return state.history;
        },
        studentModules(state) {
            return state.studentModules;
        },
        dzs(state) {
            return state.dzsF;
        },
    },
};
