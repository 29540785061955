import { createRouter, createWebHistory } from "vue-router";
import authService from "./auth";

import MainPage from "@/Main/Main";
import LoginPage from "@/Main/Login";
import PageProfile from "@/Main/Profile";

import BotList from "@/Bot/Bots";
import BotView from "@/Bot/Bot";

import UtmList from "@/Ad/Utms";
import TimerList from "@/Timer/Timers";

import UserList from "@/User/Users";

import LkPage from "@/pages/Lk";
import ParamList from "@/Params/Params";
import PageStatistic from "@/pages/Statistic";

const routes = [
    {
        path: "/",
        name: "Main",
        component: MainPage,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
    },
    {
        path: "/lk",
        name: "Lk",
        component: LkPage,
    },
    {
        path: "/bots",
        name: "BotList",
        component: BotList,
        meta: { title: "Боты" },
    },
    {
        path: "/bot",
        name: "BotView",
        component: BotView,
        meta: { title: "Бот" },
    },
    {
        path: "/timers",
        name: "TimerList",
        component: TimerList,
        meta: { title: "Настройки" },
    },
    {
        path: "/params",
        name: "ParamList",
        component: ParamList,
        meta: { title: "Параметры бота" },
    },
    {
        path: "/utms",
        name: "UtmList",
        component: UtmList,
        meta: { title: "Utm бота" },
    },
    {
        path: "/users",
        name: "UserList",
        component: UserList,
        meta: { title: "Ученики" },
    },
    {
        path: "/statistic",
        name: "PageStatistic",
        component: PageStatistic,
        meta: { title: "Статистика" },
    },
    {
        path: "/profile",
        name: "PageProfile",
        component: PageProfile,
        meta: { title: "Профиль" },
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "BotTeachMe";

    if (
        // Avoid an infinite redirect
        to.name !== "Login" &&
        to.name !== "Lk" &&
        to.name !== "Main" &&
        // make sure the user is authenticated
        !authService.isLogged()
    ) {
        // redirect the user to the login page
        // this.$router.push('/')
        return { name: "Login" };
    }
    window.scrollTo(0, 0);
});

export default router;
