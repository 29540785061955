<template>
    <svg
        v-if="name == 'bot'"
        class="fill-stroke transition duration-300 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path class="st0" d="M4,7h16c1.1,0,2,0.9,2,2v11c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V9C2,7.9,2.9,7,4,7z" />
        <path class="st0" d="M11.9,1.2v2.5" />
        <path
            class="st0"
            d="M8.1,3.8h7.5c0.7,0,2.2,1.2,2.2,1.4v1.4c0,0.2-0.5,0.4-1.2,0.4H7.1C6.5,7,5.9,6.8,5.9,6.6V5.2
	C5.9,5,7.5,3.8,8.1,3.8z"
        />
        <path class="st0" d="M5.7,22v-0.8c0-1.1,1.2-2,2.7-2h6.9c1.5,0,2.7,0.9,2.7,2V22" />
        <circle class="st0" cx="7.1" cy="13.1" r="2.4" />
        <circle class="st0" cx="16.7" cy="13.1" r="2.4" />
        <path class="st0" d="M4.6,13.1H2" />
        <path class="st0" d="M22,13.1h-2.6" />
    </svg>
    <svg
        v-if="name == 'text'"
        class="fill-stroke transition duration-300 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M20 7h-9" />
        <path d="M14 17H5" />
        <circle cx="17" cy="17" r="3" />
        <circle cx="7" cy="7" r="3" />
    </svg>
    <svg
        v-if="name == 'setting'"
        class="fill-stroke transition duration-300 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M20 7h-9" />
        <path d="M14 17H5" />
        <circle cx="17" cy="17" r="3" />
        <circle cx="7" cy="7" r="3" />
    </svg>
    <svg
        v-if="name == 'user'"
        class="fill-stroke fill-white transition duration-300 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 59.018 59.018"
        stroke-width="0"
    >
        <path
            d="M58.741,56.809l-5.969-6.244c1.746-1.919,2.82-4.458,2.82-7.25c0-5.953-4.843-10.796-10.796-10.796S34,37.361,34,43.314
		S38.843,54.11,44.796,54.11c2.441,0,4.688-0.824,6.499-2.196l6.001,6.277c0.196,0.205,0.459,0.309,0.723,0.309
		c0.249,0,0.497-0.092,0.691-0.277C59.108,57.841,59.123,57.208,58.741,56.809z M36,43.314c0-4.85,3.946-8.796,8.796-8.796
		s8.796,3.946,8.796,8.796s-3.946,8.796-8.796,8.796S36,48.164,36,43.314z"
        />
        <path
            d="M31,43.314c0-6.383,4.363-11.751,10.259-13.319c-1.01-2.515-1.807-5.313-1.996-8.186c-0.143-2.147-0.547-3.938-1.237-5.473
		c-1.424-3.164-4.469-6.947-10.91-7c-10.964,0.09-11.33,11.206-11.332,11.32c-0.125,6.47-4.134,12.855-5.851,15.3
		c-0.467,0.665-0.616,1.312-0.444,1.921c0.188,0.66,0.719,1.171,1.625,1.56c1.917,0.823,4.322,1.066,4.521,1.081h6.084
		c-0.167,0.324-0.395,0.735-0.512,0.813l-9.166,3.933c-0.713,0.356-1.349,0.83-1.915,1.395C5.132,42.002,2,35.371,2,28.018
		c0-14.061,11.439-25.5,25.5-25.5S53,13.958,53,28.018c0,1.359-0.112,2.691-0.318,3.992c0.63,0.441,1.224,0.93,1.771,1.468
		C54.811,31.714,55,29.888,55,28.018c0-15.163-12.336-27.5-27.5-27.5S0,12.855,0,28.018c0,7.976,3.417,15.167,8.86,20.195
		l-0.072,0.098l0.705,0.604c3.904,3.342,8.656,5.483,13.681,6.26c0.356,0.056,0.715,0.102,1.075,0.145
		c0.391,0.045,0.782,0.084,1.176,0.112c0.579,0.043,1.162,0.071,1.75,0.078c0.062,0,0.123,0.008,0.185,0.008
		c0.017,0,0.035-0.002,0.052-0.002c0.03,0,0.059,0.002,0.089,0.002c3.045,0,5.972-0.505,8.712-1.423
		C33.041,51.566,31,47.677,31,43.314z"
        />
    </svg>

    <svg
        v-if="name == 'plus'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="9" y1="12" x2="15" y2="12"></line>
        <line x1="12" y1="9" x2="12" y2="15"></line>
    </svg>
    <svg
        v-if="name == 'upload'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
        <polyline points="16 6 12 2 8 6"></polyline>
        <line x1="12" y1="2" x2="12" y2="15"></line>
    </svg>

    <svg
        v-if="name == 'image'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
        <circle cx="8.5" cy="8.5" r="1.5" />
        <polyline points="21 15 16 10 5 21" />
    </svg>

    <svg
        v-if="name == 'audio'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M3 18v-6a9 9 0 0118 0v6" />
        <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z" />
    </svg>

    <svg
        v-if="name == 'video'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path
            d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"
        ></path>
        <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
    </svg>

    <svg
        v-if="name == 'delete'"
        class="fill-stroke text-gray-400 cursor-pointer transition duration-300 group-hover:text-red-500 hover:text-red-500"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>

        <!-- <path stroke="none" d="M0 0h24v24H0z"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="9" y1="12" x2="15" y2="12"></line>
        <line x1="12" y1="9" x2="12" y2="15"></line> -->
    </svg>

    <svg
        v-if="name == 'back'"
        class="h-8 w-8 fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
    >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"></path>
    </svg>

    <svg
        v-if="name == 'badge'"
        class="h-8 w-8 fill-stroke text-gray-800 hover:text-blue-500"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <circle cx="18" cy="18" r="3"></circle>
        <circle cx="6" cy="6" r="3"></circle>
        <path d="M6 21V9a9 9 0 0 0 9 9"></path>
    </svg>

    <svg
        v-if="name == 'trash'"
        class="fill-stroke text-gray-400 cursor-pointer transition duration-300 group-hover:text-red-500 hover:text-red-500"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
        <line x1="10" y1="11" x2="10" y2="17"></line>
        <line x1="14" y1="11" x2="14" y2="17"></line>
    </svg>

    <svg
        v-if="name == 'tl'"
        class="fill-sky-500 cursor-pointer transition duration-300 group-hover:fill-sky-400 hover:fill-sky-400"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 100 100"
        stroke-width="0"
    >
        <path
            d="M88.723,12.142C76.419,17.238,23.661,39.091,9.084,45.047c-9.776,3.815-4.053,7.392-4.053,7.392    s8.345,2.861,15.499,5.007c7.153,2.146,10.968-0.238,10.968-0.238l33.62-22.652c11.922-8.107,9.061-1.431,6.199,1.431    c-6.199,6.2-16.452,15.975-25.036,23.844c-3.815,3.338-1.908,6.199-0.238,7.63c6.199,5.246,23.129,15.976,24.082,16.691    c5.037,3.566,14.945,8.699,16.452-2.146c0,0,5.961-37.435,5.961-37.435c1.908-12.637,3.815-24.321,4.053-27.659    C97.307,8.804,88.723,12.142,88.723,12.142z"
        />
    </svg>

    <svg
        v-if="name == 'vk'"
        class="fill-blue-500 cursor-pointer transition duration-300 group-hover:fill-blue-400 hover:fill-blue-400"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 512 512"
        stroke-width="0"
    >
        <path
            d="M440.649,295.361c16.984,16.582,34.909,32.182,50.142,50.436
	c6.729,8.112,13.099,16.482,17.973,25.896c6.906,13.382,0.651,28.108-11.348,28.907l-74.59-0.034
	c-19.238,1.596-34.585-6.148-47.489-19.302c-10.327-10.519-19.891-21.714-29.821-32.588c-4.071-4.444-8.332-8.626-13.422-11.932
	c-10.182-6.609-19.021-4.586-24.84,6.034c-5.926,10.802-7.271,22.762-7.853,34.8c-0.799,17.564-6.108,22.182-23.751,22.986
	c-37.705,1.778-73.489-3.926-106.732-22.947c-29.308-16.768-52.034-40.441-71.816-67.24
	C58.589,258.194,29.094,200.852,2.586,141.904c-5.967-13.281-1.603-20.41,13.051-20.663c24.333-0.473,48.663-0.439,73.025-0.034
	c9.89,0.145,16.437,5.817,20.256,15.16c13.165,32.371,29.274,63.169,49.494,91.716c5.385,7.6,10.876,15.201,18.694,20.55
	c8.65,5.923,15.236,3.96,19.305-5.676c2.582-6.11,3.713-12.691,4.295-19.234c1.928-22.513,2.182-44.988-1.199-67.422
	c-2.076-14.001-9.962-23.065-23.933-25.714c-7.129-1.351-6.068-4.004-2.616-8.073c5.995-7.018,11.634-11.387,22.875-11.387h84.298
	c13.271,2.619,16.218,8.581,18.035,21.934l0.072,93.637c-0.145,5.169,2.582,20.51,11.893,23.931
	c7.452,2.436,12.364-3.526,16.836-8.251c20.183-21.421,34.588-46.737,47.457-72.951c5.711-11.527,10.622-23.497,15.381-35.458
	c3.526-8.875,9.059-13.242,19.056-13.049l81.132,0.072c2.406,0,4.84,0.035,7.17,0.434c13.671,2.33,17.418,8.211,13.195,21.561
	c-6.653,20.945-19.598,38.4-32.255,55.935c-13.53,18.721-28.001,36.802-41.418,55.634
	C424.357,271.756,425.336,280.424,440.649,295.361L440.649,295.361z"
        />
    </svg>
    <svg
        v-if="name == 'woman'"
        class="fill-pink-500 cursor-pointer transition duration-300 group-hover:fill-pink-400 hover:fill-pink-400"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 59.018 59.018"
        stroke-width="0"
    >
        <path
            d="M58.741,56.809l-5.969-6.244c1.746-1.919,2.82-4.458,2.82-7.25c0-5.953-4.843-10.796-10.796-10.796S34,37.361,34,43.314
		S38.843,54.11,44.796,54.11c2.441,0,4.688-0.824,6.499-2.196l6.001,6.277c0.196,0.205,0.459,0.309,0.723,0.309
		c0.249,0,0.497-0.092,0.691-0.277C59.108,57.841,59.123,57.208,58.741,56.809z M36,43.314c0-4.85,3.946-8.796,8.796-8.796
		s8.796,3.946,8.796,8.796s-3.946,8.796-8.796,8.796S36,48.164,36,43.314z"
        />
        <path
            d="M31,43.314c0-6.383,4.363-11.751,10.259-13.319c-1.01-2.515-1.807-5.313-1.996-8.186c-0.143-2.147-0.547-3.938-1.237-5.473
		c-1.424-3.164-4.469-6.947-10.91-7c-10.964,0.09-11.33,11.206-11.332,11.32c-0.125,6.47-4.134,12.855-5.851,15.3
		c-0.467,0.665-0.616,1.312-0.444,1.921c0.188,0.66,0.719,1.171,1.625,1.56c1.917,0.823,4.322,1.066,4.521,1.081h6.084
		c-0.167,0.324-0.395,0.735-0.512,0.813l-9.166,3.933c-0.713,0.356-1.349,0.83-1.915,1.395C5.132,42.002,2,35.371,2,28.018
		c0-14.061,11.439-25.5,25.5-25.5S53,13.958,53,28.018c0,1.359-0.112,2.691-0.318,3.992c0.63,0.441,1.224,0.93,1.771,1.468
		C54.811,31.714,55,29.888,55,28.018c0-15.163-12.336-27.5-27.5-27.5S0,12.855,0,28.018c0,7.976,3.417,15.167,8.86,20.195
		l-0.072,0.098l0.705,0.604c3.904,3.342,8.656,5.483,13.681,6.26c0.356,0.056,0.715,0.102,1.075,0.145
		c0.391,0.045,0.782,0.084,1.176,0.112c0.579,0.043,1.162,0.071,1.75,0.078c0.062,0,0.123,0.008,0.185,0.008
		c0.017,0,0.035-0.002,0.052-0.002c0.03,0,0.059,0.002,0.089,0.002c3.045,0,5.972-0.505,8.712-1.423
		C33.041,51.566,31,47.677,31,43.314z"
        />
    </svg>
</template>

<script>
export default {
    name: "IcoBig",
    props: {
        name: String,
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
