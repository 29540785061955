<template>
    <h3 class="text-lg text-center mb-2">{{ title }}</h3>

    <hr />

    <input
        type="text"
        class="font-sans block text-sm w-full py-2 px-3 mb-4 ring-1 ring-slate-900/10 text-slate-500 rounded shadow-sm"
        placeholder="Поиск"
        v-model="findme"
        @input="find()"
    />

    <hr />

    <div v-if="list?.list?.length > 0" class="max-h-80 h-fit overflow-auto">
        <draggable group="m" @change="sortParamData()" itemKey="id" :list="list.list">
            <template #item="{ element }">
                <div
                    v-if="this.s.popup == 'segments'"
                    class="flex justify-between bg-origin-border px-3 py-2 rounded cursor-pointer hover:bg-sky-100"
                    :class="{ 'bg-sky-200': param_id === element.id }"
                    @click.stop="setTimerParam({ type: 'segments', param_id: element.id })"
                >
                    <div class="flex">
                        <div class="mr-2">{{ element.name }}</div>
                        <div class="text-[9px] text-gray-400">{{ element.sort }}</div>
                    </div>
                    <div v-if="this.s.popup != 'types'" class="align-middle self-center justify-self-end" @click.stop="delParamData(element.id)">
                        <IcoBig name="trash" :size="4" />
                    </div>
                </div>
                <div
                    v-else
                    class="flex justify-between bg-origin-border px-3 py-2 rounded cursor-pointer hover:bg-sky-100"
                    :class="{ 'bg-sky-200': param_id === element.id }"
                    @click.stop="setTimerParam({ type: this.s.popup, param_id: element.id })"
                >
                    <div class="flex">
                        <div class="mr-2">{{ element.name }}</div>
                        <div class="text-[9px] text-gray-400">{{ element.sort }}</div>
                    </div>
                    <div v-if="this.s.popup != 'types'" class="align-middle self-center justify-self-end" @click.stop="delParamData(element.id)">
                        <IcoBig name="trash" :size="4" />
                    </div>
                </div>
            </template>
        </draggable>
    </div>

    <hr v-if="this.s.popup != 'types'" />

    <div v-if="this.s.popup != 'types'" class="flex group mt-4">
        <input
            type="text"
            class="block text-sm w-full py-2 px-3 mr-4 ring-1 ring-slate-900/10 text-slate-500 rounded"
            placeholder="Добавить название"
            v-model="name"
            @keyup.enter="add()"
        />
        <div class="text-gray-400 cursor-pointer" @click="add()"><IcoBig name="plus" /></div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useGroupStore } from "@/store/group";
import { useMessageStore } from "@/store/message";
import { useTimerStore } from "@/store/timer";

import draggable from "vuedraggable";

export default {
    name: "TimerParams",

    components: {
        draggable,
    },

    data() {
        return {
            param_id: "",
            title: "",
            name: "",
            findme: "",
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useGroupStore, ["groups"]),
        ...mapState(useMessageStore, ["types", "modules", "variables", "themes", "segments", "addParamData", "delParamData"]),
        ...mapState(useTimerStore, ["timer", "setTimerParam"]),

        list() {
            if (!this.types || !this.modules || !this.variables || !this.themes) return null;

            let data = { title: "", listAll: [], list: [], param_id: null };

            if (this.s.popup == "groups") {
                data.title = "Группа";
                data.list = data.listAll = this.groups;
                data.param_id = this.timer?.group_id;
            }
            if (this.s.popup == "modules") {
                data.title = "Модули";
                data.list = data.listAll = this.modules;
                data.param_id = this.timer?.module_id;
            }
            if (this.s.popup == "types") {
                data.title = "Тип";
                data.list = data.listAll = this.types;
                data.param_id = this.timer?.type_id;
            }
            if (this.s.popup == "variables") {
                data.title = "Сохранить ответ";
                data.list = data.listAll = this.variables;
                data.param_id = this.timer?.variable_id;
            }
            if (this.s.popup == "themes") {
                data.title = "Темы";
                data.list = data.listAll = this.themes;
                data.param_id = this.timer?.theme_id;
            }
            if (this.s.popup == "segments") {
                data.title = "Сегменты";
                data.list = data.listAll = this.segments;
                data.param_id = this.timer?.segment_id;
            }

            return data;
        },
    },

    methods: {
        // ...mapActions(["addParamData", "sortParamData", "closePopup"]),

        add() {
            if (this.name.length < 1) return;

            this.addParamData(this.name);
            this.name = "";
        },

        // перенести в coputed
        // find() {
        //     this.listAll = this.list;

        //     if (this.findme != "" && this.findme != undefined) {
        //         this.listAll = this.listAll.filter((item) => item.name.toLowerCase().indexOf(this.findme.toLowerCase()) !== -1);
        //     }
        // },
    },
    mounted() {},
};
</script>
