<template>
    <div class="flex h-screen max-h-screen">
        <div class="relative pb-10 w-[440px] bg-teal-100 overflow-y-scroll">
            <div
                class="sticky top-0 left-0 right-0 h-16 overflow-hidden p-4 z-50 flex items-center font-semibold bg-teal-800/50 backdrop-blur-sm shadow"
            >
                <div class="relative w-full">
                    <input
                        type="text"
                        class="block text-sm w-full py-2 px-3 text-teal-600 rounded shadow-inner border-none"
                        placeholder="Поиск ученика"
                        v-model="s.find"
                        @input="filterUser(s.find)"
                    />
                    <div v-if="s.find?.length > 0" class="group absolute top-2 right-2" @click.stop="clearFilterUser()">
                        <IcoBig name="delete" :size="5" />
                    </div>
                </div>
            </div>

            <div class="h-fit p-5">
                <div class="latolight w-full block text-2xl text-teal-900 tracking-tight mb-4">Ученики</div>

                <div class="flex flex-wrap gap-2 mb-4">
                    <div
                        class="bg-white py-1 px-2 rounded text-xs text-center cursor-pointer hover:bg-cyan-400 hover:text-white"
                        @click="setBotId(null)"
                        :class="{ '!bg-cyan-200': !s.bot_id }"
                    >
                        Все
                    </div>
                    <div
                        v-for="bot in bots"
                        :key="bot"
                        class="bg-white py-1 px-2 rounded text-xs text-center cursor-pointer hover:bg-cyan-400 hover:text-white"
                        @click="setBotId(bot.id)"
                        :class="{ '!bg-cyan-200': s.bot_id === bot.id }"
                    >
                        {{ bot.name }}
                    </div>
                </div>

                <div class="flex flex-wrap gap-2 mb-4">
                    <div
                        class="bg-white py-1 px-2 rounded text-xs text-center cursor-pointer hover:bg-cyan-400 hover:text-white"
                        @click="setStatus(null)"
                        :class="{ '!bg-cyan-200': !s.status }"
                    >
                        Все
                    </div>
                    <div
                        class="bg-white py-1 px-2 rounded text-xs text-center cursor-pointer hover:bg-cyan-400 hover:text-white"
                        @click="setStatus(9)"
                        :class="{ '!bg-cyan-200': s.status == 9 }"
                    >
                        Выключен
                    </div>

                    <div
                        class="bg-white py-1 px-2 rounded text-xs text-center cursor-pointer hover:bg-cyan-400 hover:text-white"
                        @click="setStatus(1)"
                        :class="{ '!bg-cyan-200': s.status == 1 }"
                    >
                        Активен
                    </div>
                </div>

                <template v-if="users?.length > 0">
                    <!-- <div @click="toggleListView()">ВИД {{ s.view }}</div> -->

                    <!-- <template v-if="s.view == 'cart'">
                        <div class="grid grid-cols-4 gap-3 m0--5">
                            <div v-for="item in users" :key="item" class="relative mesbox">
                                <div class="mes bg-origin-border" @click="pickUser(item)" :class="{ '!bg-cyan-200': user?.profile?.id === item.id }">
                                    <div class="text-xs text-center">{{ item.picture }} {{ item.name }} {{ item.secondname }}</div>
                                </div>
                            </div>
                        </div>
                    </template> -->

                    <!-- <template v-else> -->
                    <div v-for="item in users" :key="item" @click="pickUser(item)" class="py-px w-full group hover:py-1 relative duration-500">
                        <div
                            :class="{ '!bg-white': user?.id === item.id }"
                            class="grid grid-cols-8 items-center gap-2 bg-white/50 py-1 px-2 rounded text-xs cursor-pointer hover:bg-white"
                        >
                            <img
                                v-if="item.picture"
                                :src="'https://yii.botteach.ru/users/mini/' + item.picture"
                                class="rounded-full w-8 h-8 shrink-0"
                            />
                            <div v-else class="flex justify-center items-center rounded-full w-8 h-8 shrink-0 bg-slate-200 text-slate-500">
                                {{ item.name?.slice(0, 1) }}
                            </div>
                            <div class="flex items-center text-sm col-span-4 h-8 overflow-hidden leading-5">
                                {{ item.name }} {{ item.secondname }}
                            </div>
                            <div class="">{{ moment().diff(moment(item.create_at, "YYYY-MM-DD"), "days") }} д.</div>
                            <div class="">{{ item.status }}</div>
                        </div>
                    </div>
                    <!-- </template> -->
                </template>

                <div v-else class="w-full my-4 text-center text-slate-400">
                    <p class="latolight text-xl">Нет ни одного пользователя</p>
                    <p>Возможно пора рассказать про своих ботов</p>
                </div>
            </div>
        </div>

        <div v-if="user" class="w-full flex-1">
            <div class="sticky top-0 left-0 right-0 h-16 overflow-hidden z-50 font-semibold bg-teal-800/50 backdrop-blur-sm shadow">
                <div class="w-full grid grid-cols-5 relative p-6">
                    <div class="text-center text-white">Профиль</div>
                    <div class="text-center text-white/50">Оплаты</div>
                    <div class="text-center text-white/50">Прогресс</div>
                    <div class="text-center text-white/50">Сегменты</div>
                    <div class="text-center text-white/50">Рассылки</div>
                </div>
            </div>

            <div class="flex-1 flex gap-5 p-5 relative">
                <div class="w-2/3">
                    <div class="flex gap-4 my-2">
                        <img v-if="user.picture" class="rounded-xl w-24" :src="'https://yii.botteach.ru/users/mini/' + user.picture" />
                        <div v-else class="flex justify-center items-center rounded-xl w-24 h-24 shrink-0 bg-slate-200 text-slate-500">
                            {{ user.name?.slice(0, 1) }}
                        </div>
                        <div class="flex flex-col">
                            <span class="text-slate-400">#{{ user.id }}</span>
                            <div class="text-lg font-bold">{{ user.name }} {{ user.secondname }}</div>
                            <span class="text-slate-400">{{ user.tl }}</span>
                        </div>

                        <div class="ml-auto self-center place-self-end" @click="delUser(user)">
                            <IcoBig name="trash" :size="5" />
                        </div>
                    </div>

                    <div v-for="student in user.students" :key="student">
                        <div class="grid grid-cols-9 gap-2 items-center bg-slate-50 border-slate-100 p-2 rounded mb-1">
                            <div class="col-span-2 flex items-center gap-2">
                                <div v-if="student.net == 2"><IcoBig name="tl" :size="4" /></div>
                                <div v-if="student.net == 1"><IcoBig name="vk" :size="4" /></div>
                                <p class="text-base font-latobold">{{ student.bot_name }}</p>
                                {{ student.id }}
                            </div>

                            <div class="col-span-2 flex">
                                {{ moment().diff(moment(student.create_at, "YYYY-MM-DD"), "days") }} д.

                                <!-- <div v-if="student.start">
                                    с {{ moment(student.start).format("DD.MM.YYYY") }} по {{ moment(student.finish).format("DD.MM.YYYY") }}
                                </div>
                                <p v-else>нет даты подписки</p> -->
                            </div>

                            <div class="col-span-3 grid grid-cols-3 justify-center gap-4">
                                <p>M {{ student.module_id }}</p>
                                <p>L {{ student.level }}</p>
                                <p>R {{ student.role }}</p>
                            </div>

                            <!-- <div class="col-span-3 flex justify-between gap-2">
                                <p>Д {{ student.money }}</p>
                                <p>Ж {{ student.life }}</p>
                                <p>Э {{ student.energy }}</p>
                                <p>Б {{ student.point }}</p>
                                <p>Д {{ student.friend }}</p>
                            </div> -->

                            <div class="col-span-2 flex items-center gap-4 p-2">
                                <IconRe />

                                <div @click="getStudentHistory({ bot_id: student.bot_id, student_id: student.id })"><IconEye /></div>

                                <div v-if="student.status == 1" class="circle bg-teal-500"></div>
                                <div v-if="student.status == 9" class="circle bg-pink-500"></div>
                            </div>
                        </div>
                    </div>

                    <!-- <div v-if="user.students" class="grid grid-cols-2 gap-4 mt-8">
                        <div v-for="student in user.students" :key="student">
                            <div class="bg-slate-50 border-slate-100 border-b rounded-xl">
                                <div class="p-8 relative">
                                    <div class="absolute top-2 right-4">
                                        <div v-if="student.net == 2"><IcoBig name="tl" :size="4" /></div>
                                        <div v-if="student.net == 1"><IcoBig name="vk" :size="4" /></div>
                                    </div>

                                    <p class="text-base font-latobold mb-4 w-40">{{ student.bot_name }}</p>

                                    <div class="mb-4">
                                        <p v-if="student.start && student.finish">
                                            с {{ moment(student.start).format("DD.MM.YYYY") }} по {{ moment(student.finish).format("DD.MM.YYYY") }}
                                        </p>
                                        <p v-else>нет даты подписки</p>
                                    </div>

                                    <div class="flex justify-between mb-4">
                                        <p>M {{ student.module_id }}</p>
                                        <p>L {{ student.level }}</p>
                                        <p>R {{ student.role }}</p>
                                    </div>

                                    <div class="flex justify-between mb-4">
                                        <p>Д {{ student.money }}</p>
                                        <p>Ж {{ student.life }}</p>
                                        <p>Э {{ student.energy }}</p>
                                        <p>Б {{ student.point }}</p>
                                        <p>Д {{ student.friend }}</p>
                                    </div>
                                </div>

                                <div class="bg-slate-50 text-slate-500 border-slate-100 rounded-b-xl flex items-center p-4">
                                    <div class="flex justify-center items-center">
                                        <div>
                                            <svg width="24" height="24" fill="none">
                                                <path
                                                    d="m10 12 8-6v12l-8-6Z"
                                                    fill="currentColor"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M6 6v12"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </div>

                                    <div
                                        class="bg-white text-slate-900 dark:bg-slate-100 dark:text-slate-700 flex-none -my-2 mx-auto w-20 h-20 rounded-full ring-1 ring-slate-900/5 shadow-md flex items-center justify-center"
                                    >
                                        <svg width="30" height="32" fill="currentColor">
                                            <rect x="6" y="4" width="4" height="24" rx="2" />
                                            <rect x="20" y="4" width="4" height="24" rx="2" />
                                        </svg>
                                    </div>

                                    <div type="button" @click="getStudentHistory({ bot_id: student.bot_id, student_id: student.id })">
                                        <svg width="24" height="24">
                                            <path
                                                d="M7 6.931C7 5.865 7.853 5 8.905 5h6.19C16.147 5 17 5.865 17 6.931V19l-5-4-5 4V6.931Z"
                                                fill="currentColor"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="w-1/3 relative">
                    <div v-if="history && history.length > 0">
                        <input
                            type="text"
                            class="block text-sm w-full py-2 px-3 mb-2 ring-1 ring-slate-900/10 text-slate-500 rounded shadow-sm"
                            placeholder="Поиск по истории"
                            v-model="find"
                            @input="findMs"
                        />

                        <div
                            class="py-1 px-2 bg-slate-300 hover:bg-sky-400 my-2 rounded cursor-pointer"
                            @click="scheduleOne({ bot_id: student.bot_id, student_id: student.id })"
                        >
                            Пересоздать расписание
                        </div>

                        <div class="h-[90vh] overflow-y-scroll">
                            <div
                                v-for="h in history"
                                :key="h"
                                class="relative bg-slate-100 text-sm rounded mb-1 p-2"
                                :class="{ '!bg-teal-100': [2, 4].includes(h.status), '!bg-yellow-100': h.status == 1 }"
                            >
                                <p class="text-xs text-slate-400">{{ moment(h.create_at).format("DD.MM HH:mm") }}</p>
                                <p>{{ h.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="s.popup" @click="closePopup()" class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-50" id="overlay"></div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";
import { useUserStore } from "@/store/user";
import moment from "moment";

import IcoBig from "../components/ui/IcoBig.vue";

export default {
    name: "UserList",

    components: { IcoBig },

    data() {
        return { moment: moment };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setSettings", "setPopup", "closePopup", "toggleListView"]),
        ...mapState(useBotStore, ["bots", "bot", "getBots"]),
        ...mapState(useUserStore, [
            "users",
            "user",
            "history",
            "getUsers",
            "filterUser",
            "clearFilterUser",
            "pickUser",
            "scheduleOne",
            "getStudentHistory",
        ]),
    },

    methods: {
        setBotId(id) {
            // console.log("setBotId", id);
            this.s.bot_id = id;
            this.setSettings(this.s);
            this.getUsers();
        },

        setStatus(status) {
            this.s.status = status;
            this.setSettings(this.s);
            this.getUsers();
        },
    },

    mounted() {
        this.getBots();
        this.getUsers();
    },
};
</script>
