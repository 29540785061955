import API from "../../services/api";
// import router from "@/router/router";

export default {
    state: {
        messages: [],
        messagesF: [],
        message: null,
        nextMessageId: null,
        targetList: [],
        allCodes: [
            { code: "cMenu", name: "Меню" },
            { code: "cInfinity", name: "Тренировка" },
            { code: "cLesson", name: "Все уроки" },
            { code: "cDz", name: "Задания" },
        ],
        codeList: [],
        btns: [],
        btn: [],
        modules: [],
        types: [],
        variables: [],
        themes: [],
        segments: [],
        files: [],
    },

    mutations: {
        setMessages: (state, messages) => {
            state.messages = messages;
            if (messages) {
                // localStorage.setItem("messages", JSON.stringify(messages));
            } else {
                localStorage.removeItem("messages");
            }
        },
        setMessagesF: (state, messages) => {
            let plus = messages.filter((item) => parseInt(item.status) == 100);
            if (messages?.length > 0 && plus?.length == 0) {
                messages.push({ status: 100, name: "сообщение" });
            }
            state.messagesF = messages;
        },
        setMessage: (state, message) => {
            state.message = message;
            if (message) {
                localStorage.setItem("message", JSON.stringify(message));
            } else {
                localStorage.removeItem("message");
            }
        },
        setBtns: (state, btns) => {
            state.btns = btns;
            if (btns) state.message.btns = JSON.stringify(btns);
        },
        setBtn: (state, btn) => {
            state.btn = btn;
        },
        setTargetMessage: (state, target) => {
            state.targetList = target;
        },
        setCodeList: (state, codes) => {
            state.codeList = codes;
        },
        setModules: (state, modules) => {
            state.modules = modules;
            localStorage.setItem("modules", JSON.stringify(modules));
        },
        setTypes: (state, types) => {
            state.types = types;
        },
        setVariables: (state, variables) => {
            state.variables = variables;
        },
        setThemes: (state, themes) => {
            state.themes = themes;
        },
        setSegments: (state, segments) => {
            state.segments = segments;
            localStorage.setItem("segments", JSON.stringify(segments));
        },
        setFiles: (state, files) => {
            state.files = files;
        },
    },

    actions: {
        // getinGroup(ctx) {
        //     let t0 = performance.now();
        //     console.log("group", ctx.rootState.group.group.id);
        //     API.GET("api/message/group", { bot_id: ctx.rootState.bot.bot.id, group_id: ctx.rootState.group.group.id }).then((res) => {
        //         console.log("getinGroup", performance.now() - t0, res.data);
        //         if (res.data.alert) ctx.commit("setAlert", res.data.alert);
        //         if (res.data.messages) ctx.commit("setMessages", res.data.messages);
        //         if (res.data.messages) ctx.commit("setMessagesF", res.data.messages);
        //     });
        // },

        getFiles(ctx) {
            // if (!ctx.state.files || ctx.state.files?.length <= 0) {
            API.GET("api/message/get-files", { bot_id: ctx.rootState.bot.bot.id }).then((res) => {
                console.log("data", res.data);
                ctx.commit("setFiles", res.data.files);
            });
            // }
        },

        // покажем выбранное (localStorage) после перезагрузки
        showAll(ctx) {
            console.log("showAll", performance.now());

            ctx.dispatch("loadPath");
            ctx.dispatch("loadGroup");

            let group = ctx.rootState.group?.group;
            console.log("group_id", ctx.rootState.group?.group?.id);

            // отфильтруем вложенные группы
            let gs = ctx.rootState.group.groups.filter((item) => item.parent == group.id);
            ctx.commit("setGroupsF", gs);

            // отфильтруем сообщения
            let ms = ctx.state.messages.filter((item) => item.gid == group.id);
            ctx.commit("setMessagesF", ms);

            // покажем выбранное сообщение
            let message = JSON.parse(localStorage.getItem("message"));
            if (message) {
                ctx.dispatch("pickMessage", message);
            }
        },

        // СООБЩЕНИЯ

        findM(ctx, find) {
            if (find != "" && find != undefined && parseInt(find.length) >= 3) {
                let list = ctx.state.messages.filter((item) => {
                    let id = null;
                    let name = null;
                    let m = null;
                    id = item.id.toString().indexOf(find.toLowerCase()) !== -1;
                    if (item.name) {
                        name = item.name.toLowerCase().indexOf(find.toLowerCase()) !== -1;
                    }
                    if (item.m) {
                        m = item.m.toLowerCase().indexOf(find.toLowerCase()) !== -1;
                    }
                    if (id || name || m) {
                        return true;
                    }
                    return false;
                });
                ctx.commit("setMessagesF", list);
            }
        },

        clearFindM(ctx) {
            // отфильтруем сообщения
            let ms = ctx.state.messages.filter((item) => item.gid == ctx.rootState.group?.group.id);
            ctx.commit("setMessagesF", ms);
        },

        // выбрать сообщение
        pickMessage(ctx, message) {
            // console.log("pickMessage", message);
            ctx.commit("setMessage", message);
            if (message?.btns) {
                ctx.commit("setBtns", JSON.parse(message?.btns));
            } else {
                ctx.commit("setBtns", null);
            }
        },

        addMessage(ctx, message = false) {
            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.group.group.bot_id);
            formData.append("group_id", ctx.rootState.group.group.id);
            if (message) {
                formData.append("message_id", message.id);
                formData.append("sort", message.sort);

                // Пересортируем сообщения справа в полном списке
                ctx.state.messages.forEach((item) => {
                    if (item.gid == ctx.rootState.group.group.id && item.sort >= message.sort) {
                        item.sort++;
                        return;
                    }
                });
            } else {
                if (ctx.state.messagesF?.length > 0) {
                    // -2 потому что последнее это "+", нужно предпоследнее
                    formData.append("sort", ctx.state.messagesF[ctx.state.messagesF.length - 2].sort + 1);
                } else {
                    formData.append("sort", 1);
                }
            }
            console.log("sort", formData.sort);

            API.POST("api/message/add", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                if (res.data.message) {
                    ctx.state.messages.push(res.data.message);
                    ctx.commit("setMessages", ctx.state.messages);
                    ctx.dispatch("filterMessages", ctx.rootState.group.group.id);
                    ctx.dispatch("pickMessage", res.data.message);
                }
                ctx.dispatch("increment");
            });
        },

        sortMessage(ctx) {
            // console.log("sortMessage");
            let sort = [];
            ctx.state.messagesF.forEach((m) => sort.push(m.id));

            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.group.group.bot_id);
            formData.append("group_id", ctx.rootState.group.group.id);
            formData.append("sort", sort);

            API.POST("api/message/sort", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                // if (res.data.messages) ctx.commit("setMessages", res.data.messages);
                // let ms = res.data.messages.filter((item) => item.gid == ctx.rootState.group.group.id);
                // console.log("ms", ms);

                ctx.commit("setMessagesF", res.data.messagesF);
            });
        },

        // drag&grop переместить сообщение в группу
        putMessageToGroup(ctx, e) {
            if (!e) {
                return;
            }
            console.log("eeee", e.originalEvent);
            let group_id = 0;
            e.originalEvent.path.forEach((element) => {
                if (element?.id?.slice(0, 1) == "g" && parseInt(element?.id?.slice(1)) > 0) {
                    group_id = parseInt(element?.id?.slice(1));
                }
            });

            // console.log("group_id", group_id);

            let m_id = e.item?.id?.slice(1);
            if (group_id && parseInt(group_id) > 0 && m_id && parseInt(m_id) > 0) {
                let formData = new FormData();
                formData.append("group_id", group_id);
                formData.append("id", e.item?.id?.slice(1));

                API.POST("api/message/to-group", formData).then((res) => {
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);

                    // console.log("m_id", m_id);

                    let i = ctx.state.messages.findIndex(function (item) {
                        return parseInt(item.id) === parseInt(m_id);
                    });
                    ctx.state.messages[i].gid = group_id;
                    ctx.commit("setMessages", ctx.state.messages);

                    let msF = ctx.state.messagesF.filter((item) => parseInt(item.id) !== parseInt(m_id));
                    ctx.commit("setMessagesF", msF);
                });
            }
        },

        saveMessage(ctx, m) {
            ctx.commit("setMessage", m);

            let formData = new FormData();
            Object.keys(m).forEach(function (key) {
                formData.append(key, this[key]);
            }, m);

            API.POST("api/message/save", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);

                ctx.state.messages.forEach((item, i) => {
                    if (item.id === m.id) {
                        ctx.state.messages[i] = m;
                        return;
                    }
                });
                ctx.commit("setMessages", ctx.state.messages);

                ctx.state.messagesF.forEach((item, i) => {
                    if (item.id === m.id) {
                        ctx.state.messagesF[i] = m;
                        return;
                    }
                });
                ctx.commit("setMessagesF", ctx.state.messagesF);
            });
        },

        // добавить в сообщение Goto - id следующего сообщения
        setGoto(ctx, id) {
            ctx.state.message.goto = id;
            ctx.commit("setMessage", ctx.state.message);

            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("id", ctx.state.message.id);
            formData.append("goto", id);

            API.POST("api/message/set-goto", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },

        delMessage(ctx, m) {
            let ms = ctx.state.messages.filter((item) => item.id !== m.id);
            ctx.commit("setMessages", ms);
            ctx.dispatch("filterMessages", m.gid);

            ctx.commit("setMessage", null);
            ctx.commit("setBtns", null);

            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("group_id", ctx.rootState.group.group.id);
            formData.append("id", m.id);

            API.POST("api/message/delete", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.dispatch("decrement");
            });
        },

        generateFromTxt(ctx, data) {
            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("group_id", ctx.rootState.group.group.id);
            formData.append("filename", data.filename);
            formData.append("module_id", data.module_id);
            formData.append("theme_id", data.theme_id);
            if (ctx.state.messagesF?.length > 1) {
                formData.append("sort", ctx.state.messagesF[ctx.state.messagesF.length - 2].sort + 1);
            } else {
                formData.append("sort", 1);
            }

            API.POST("api/generate/txt", formData).then((res) => {
                console.log("generate", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                if (res.data.messages) ctx.state.messages.push(res.data.messages);
                ctx.commit("setMessages", ctx.state.messages);
                ctx.dispatch("filterMessages", data.group_id);
            });
        },

        pickBtn(ctx, btn) {
            ctx.commit("setBtn", btn);
            ctx.dispatch("showPopup", "btn");
        },

        addBtn(ctx, row = 0) {
            let btns = ctx.state.btns;
            if (btns) {
                if (btns[row]) {
                    btns[row].push(["КНОПКА", "", "primary"]);
                } else {
                    btns[row] = [["КНОПКА", "", "primary"]];
                }
            } else {
                btns = [[["КНОПКА", "", "primary"]]];
            }

            ctx.dispatch("saveBtns", btns);
        },

        saveBtns(ctx, btns) {
            btns = btns.filter((row) => row.length > 0);
            ctx.commit("setBtns", btns);

            ctx.state.message.btns = JSON.stringify(btns);
            ctx.commit("setMessage", ctx.state.message);

            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("id", ctx.state.message.id);
            formData.append("btns", JSON.stringify(btns));

            API.POST("api/message/save-btns", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },

        delBtn(ctx, btn) {
            let newbtns = ctx.state.btns.map((row) => {
                return row.filter((b) => b != btn);
            });

            ctx.dispatch("saveBtns", newbtns);
        },

        hoverNext(ctx, id = false) {
            if (id) {
                ctx.state.nextMessageId = parseInt(id);
            } else {
                ctx.state.nextMessageId = null;
            }
        },

        // перейти по нажатию кнопки
        goNext(ctx, id) {
            let next = ctx.state.messages.filter((item) => item.id == id);
            if (next[0]) {
                ctx.dispatch("pickMessage", next[0]);
                ctx.commit("setAlert", { msg: "Телепортация завершена", type: "success" });
            }
        },

        targetMessage(ctx, findme) {
            let target = [];
            let codes = [];
            if (findme != "" && findme != undefined) {
                target = ctx.state.messages.filter((item) => item.name.toLowerCase().indexOf(findme.toLowerCase()) !== -1);
                target = target.filter((item) => item.id !== ctx.state.message.id);
                ctx.commit("setTargetMessage", target.slice(0, 3));

                codes = ctx.state.allCodes.filter((item) => item.name.toLowerCase().indexOf(findme.toLowerCase()) !== -1);
                ctx.commit("setCodeList", codes.slice(0, 3));
            }
        },

        // delImg(ctx, data) {
        //     console.log("delImg " + data.img);
        //     data.item.images = data.item.images.filter((t) => t !== data.img);

        //     let formData = new FormData();
        //     formData.append("id", data.img.id);
        //     formData.append("oid", data.img.oid);
        //     API.POST("ajax/del-img", formData);
        // },
        //

        //

        // ПАРМЕТРЫ В СООБЩЕНИИ
        setParams(ctx, data) {
            if (data.modules) ctx.commit("setModules", data.modules);
            if (data.types) ctx.commit("setTypes", data.types);
            if (data.variables) ctx.commit("setVariables", data.variables);
            if (data.themes) ctx.commit("setThemes", data.themes);
            if (data.segments) ctx.commit("setSegments", data.segments);
        },

        addParamData(ctx, name) {
            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("name", name);
            formData.append("type", ctx.rootState.s.popup);

            API.POST("api/message/add-param", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.dispatch("setParams", res.data);
            });
        },

        sortParamData(ctx) {
            let sort = [];
            ctx.state.modules.forEach((m) => sort.push(m.id));
            console.log("sort", sort);
            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.group.group.bot_id);
            formData.append("sort", sort);

            API.POST("api/message/sort-param", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.dispatch("setParams", res.data);
            });
        },

        pickParamData(ctx, param) {
            if (!ctx.state.message?.id) {
                return;
            }

            console.log("popup", ctx.rootState.s.popup);
            console.log("param", param);

            let m = ctx.state.message;

            if (ctx.rootState.s.popup == "modules") m.module_id = param.id;
            if (ctx.rootState.s.popup == "types") m.type_id = param.id;
            if (ctx.rootState.s.popup == "variables") m.variable_id = param.id;
            if (ctx.rootState.s.popup == "themes") m.theme_id = param.id;
            // if (ctx.rootState.s.popup == "segments") m.segment_id = param.id;

            ctx.commit("setMessage", m);

            let formData = new FormData();
            formData.append("id", m.id);
            formData.append("param_id", param.id);
            formData.append("type", ctx.rootState.s.popup);

            ctx.rootState.s.popup = null;
            ctx.dispatch("setSettings", ctx.rootState.s);

            API.POST("api/message/set-param", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);

                ctx.state.messages.forEach((item, i) => {
                    if (item.id === m.id) {
                        ctx.state.messages[i] = m;
                        return;
                    }
                });
                ctx.commit("setMessages", ctx.state.messages);

                ctx.state.messagesF.forEach((item, i) => {
                    if (item.id === m.id) {
                        ctx.state.messagesF[i] = m;
                        return;
                    }
                });
                ctx.commit("setMessagesF", ctx.state.messagesF);
            });
        },

        clearParam(ctx, param_name) {
            if (!ctx.state.message?.id) {
                return;
            }

            let m = ctx.state.message;
            if (param_name == "module_id") m.module_id = 0;
            if (param_name == "variable_id") m.variable_id = 0;
            if (param_name == "theme_id") m.theme_id = 0;
            // if (param_name == "segment_id") m.segment_id = 0;

            ctx.commit("setMessage", m);

            let formData = new FormData();
            formData.append("id", m.id);
            formData.append("param_name", param_name);

            API.POST("api/message/clear-param", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);

                ctx.state.messages.forEach((item, i) => {
                    if (item.id === m.id) {
                        ctx.state.messages[i] = m;
                        return;
                    }
                });
                ctx.commit("setMessages", ctx.state.messages);

                ctx.state.messagesF.forEach((item, i) => {
                    if (item.id === m.id) {
                        ctx.state.messagesF[i] = m;
                        return;
                    }
                });
                ctx.commit("setMessagesF", ctx.state.messagesF);
            });
        },

        delParamData(ctx, id) {
            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("id", id);
            formData.append("type", ctx.rootState.s.popup);

            API.POST("api/message/delete-param", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.dispatch("setParams", res.data);
            });
        },

        sendMe(ctx, net) {
            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("id", ctx.state.message.id);
            formData.append("net", net);

            API.POST("api/message/sendme", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },

        // отправить студенту вручную
        send(ctx, data) {
            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("id", ctx.state.message.id);
            formData.append("net", data.net);
            formData.append("user_id", data.user_id);

            API.POST("api/message/send", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },
    },

    getters: {
        allMessages(state) {
            return state.messages;
        },
        messages(state) {
            return state.messagesF;
        },
        message(state) {
            return state.message;
        },
        btns(state) {
            return state.btns;
        },
        btn(state) {
            return state.btn;
        },
        nextMessageId(state) {
            return state.nextMessageId;
        },
        targetList(state) {
            return state.targetList;
        },
        codeList(state) {
            return state.codeList;
        },
        modules(state) {
            return state.modules;
        },
        types(state) {
            return state.types;
        },
        variables(state) {
            return state.variables;
        },
        themes(state) {
            return state.themes;
        },
        segments(state) {
            return state.segments;
        },
        files(state) {
            return state.files;
        },
    },
};
