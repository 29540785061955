<template>
    <span v-if="pic" class="block w-5 h-5 rounded-full overflow-hidden"><img :src="'https://api.tahocrm.ru/uploads/users/' + pic" /></span>
    <span v-else class="text-white text-base w-5 h-5 leading-none rounded-full flex items-center justify-center">{{ name?.slice(0, 1) }}</span>
</template>

<script>
export default {
    name: "IcoPicSmall",
    props: {
        pic: String,
        name: String,
    },
};
</script>
