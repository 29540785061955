<template>
    <div class="h-full max-l:flex max-l:justify-around max-l:overflow-y-hidden">
        <div
            class="block w-12 h-12 mx-auto mt-2 mb-8 rounded-full overflow-hidden relative cursor-pointer max-l:hidden"
            @click="$router.push('/profile')"
        >
            <img v-if="author?.picture" class="w-full h-full object-cover" :src="'https://yii.botteach.ru/users/mini/' + author?.picture" />
            <img v-else class="w-full h-full object-cover" src="https://yii.botteach.ru/users/mini/u0.png" />
        </div>

        <div v-for="item in menu" :key="item" class="cursor-pointer text-center text-slate-500">
            <div v-if="item.url == '/bots'" class="flex flex-col items-center group w-full my-4" @click="$router.push(item.url)">
                <IconHome :size="8" />
                <div class="opacity-0 text-[10px] group-hover:opacity-100 duration-300 max-l:hidden">{{ item.name }}</div>
            </div>

            <div v-if="item.url == '/bot' && bot?.id > 0" class="flex flex-col items-center group w-full my-4" @click="$router.push(item.url)">
                <IconText :size="8" />
                <div class="opacity-0 text-[10px] group-hover:opacity-100 duration-300 max-l:hidden">{{ item.name }}</div>
            </div>

            <div v-if="item.url == '/timers' && bot?.id > 0" class="flex flex-col items-center group w-full my-4" @click="$router.push(item.url)">
                <IconTimer :size="8" />
                <div class="opacity-0 text-[10px] group-hover:opacity-100 duration-300 max-l:hidden">{{ item.name }}</div>
            </div>

            <div v-if="item.url == '/params' && bot?.id > 0" class="flex flex-col items-center group w-full my-4" @click="$router.push(item.url)">
                <IconSetting :size="8" />
                <div class="opacity-0 text-[10px] group-hover:opacity-100 duration-300 max-l:hidden">{{ item.name }}</div>
            </div>

            <div v-if="item.url == '/users'" class="flex flex-col items-center group w-full my-4" @click="$router.push(item.url)">
                <IconUser :size="8" />
                <div class="opacity-0 text-[10px] group-hover:opacity-100 duration-300 max-l:hidden">{{ item.name }}</div>
            </div>

            <!-- ------ -->
            <div v-if="item.url == '/ads'" class="flex flex-col items-center group w-full my-4" @click="$router.push(item.url)">
                <IconUser :size="8" />
                <div class="opacity-0 text-[10px] group-hover:opacity-100 duration-300 max-l:hidden">{{ item.name }}</div>
            </div>

            <div v-if="item.url == '/products'" class="flex flex-col items-center group w-full my-4" @click="$router.push(item.url)">
                <IconUser :size="8" />
                <div class="opacity-0 text-[10px] group-hover:opacity-100 duration-300 max-l:hidden">{{ item.name }}</div>
            </div>
        </div>

        <!-- <div class="text-center relative py-4 max-l:hidden">
            <a class="uppercase text-sky-500 text-sm cursor-pointer transition-colors duration-300 hover:text-sky-400" @click="nextRole()">
                <span>{{ roleNames[role] }}</span>
            </a>
        </div> -->

        <p class="w-full absolute bottom-8 text-xs text-center mt-24 max-l:hidden">©<br />BOTS<br />2024</p>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAuthorStore } from "@/store/author";
import { useBotStore } from "@/store/bot";

export default {
    name: "LeftMenu",

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useAuthorStore, ["menu", "author", "setRole"]),
        ...mapState(useBotStore, ["bot"]),
    },

    mounted() {},
};
</script>
