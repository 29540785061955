import API from "@/services/api";
import moment from "moment";

export default {
    state: {
        // change active component
        statisticShow: "",
        statQualityScore: {
            category: "",
            score: "",
        },

        // values from db
        statOrders: [],
        statServices: [],
        statSalaries: [],
        statLoading: [],
        statQuality: [],
        statRating: [],

        // filtered values
        filteredStatOrders: {},
        filteredStatServices: {},
        filteredStatAllSalaries: {},
        filteredStatSalaries: {},
        filteredStatLoading: {},
        filteredStatQuality: {},
        filteredStatRating: {},
    },

    mutations: {
        statCalendar: (state, statCalendar) => {
            state.statCalendar = statCalendar;
        },
        // change active component
        changeShowPage: (state, result) => {
            localStorage.setItem("statisticShow", result);
            let localShow = localStorage.getItem("statisticShow");
            state.statisticShow = localShow;
        },
        setStatQualityScore: (state, result) => {
            localStorage.setItem("statQualityScore", JSON.stringify(result));
            let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
            state.statQualityScore = localShow;
        },

        // set values
        setStatOrders: (state, orders) => {
            state.statOrders = orders;
            state.filteredStatOrders = orders;
        },
        setStatServices: (state, services) => {
            state.statServices = services;
            state.filteredStatServices = services;
        },
        setStatSalaries: (state, salaries) => {
            state.statSalaries = salaries;
            state.filteredStatAllSalaries = salaries;
            state.filteredStatSalaries = salaries;
        },
        setStatLoading: (state, loading) => {
            state.statLoading = loading;
            state.filteredStatLoading = loading;
        },
        setStatQuality: (state, quality) => {
            state.statQuality = quality;
            state.filteredStatQuality = quality;
        },
        setStatRating: (state, rating) => {
            state.statRating = rating;
            state.filteredStatRating = rating;
        },
    },

    actions: {
        getStatShow(ctx) {
            let localShow = localStorage.getItem("statisticShow");
            if (!localShow) {
                localShow = "main";
                localStorage.setItem("statisticShow", localShow);
            }
            ctx.commit("changeShowPage", localShow);
        },

        changeStatQualityScore(ctx, { category, score }) {
            if (!localStorage.getItem("statQualityScore")) {
                localStorage.setItem("statQualityScore", JSON.stringify({ category: category, score: score }));
                let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
                ctx.commit("setStatQualityScore", localShow);
            }
            if (localStorage.getItem("statQualityScore")) {
                ctx.commit("setStatQualityScore", JSON.parse(localStorage.getItem("statQualityScore")));
            }
            if (category == 1) {
                localStorage.setItem("statQualityScore", JSON.stringify({ category: "1", score: score }));
                let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
                ctx.commit("setStatQualityScore", localShow);
            }
            if (category == 2) {
                localStorage.setItem("statQualityScore", JSON.stringify({ category: "2", score: score }));
                let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
                ctx.commit("setStatQualityScore", localShow);
            }
            if (category == 3) {
                localStorage.setItem("statQualityScore", JSON.stringify({ category: "3", score: score }));
                let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
                ctx.commit("setStatQualityScore", localShow);
            }
            if (category != 1 && category != 2 && category != 3) {
                localStorage.setItem("statQualityScore", JSON.stringify({ category: category, score: score }));
                let localShow = JSON.parse(localStorage.getItem("statQualityScore"));
                ctx.commit("setStatQualityScore", localShow);
            }
        },

        getStatQualityScore(ctx) {
            if (localStorage.getItem("statQualityScore")) {
                ctx.commit("setStatQualityScore", JSON.parse(localStorage.getItem("statQualityScore")));
            }
        },

        // get values
        getStatOrders(ctx) {
            API.GET("statistic/orders").then((res) => {
                ctx.commit("setStatOrders", res.data.orders);
                // console.log("Orders", res.data.orders);

                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findStatOrders", ctx.rootState.s);
                });
            });
        },
        getStatServices(ctx) {
            API.GET("statistic/prices").then((res) => {
                ctx.commit("setStatServices", res.data.prices);
                // console.log("Services", res.data.prices);

                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findStatServices", ctx.rootState.s);
                });
            });
        },
        getStatSalaries(ctx) {
            API.GET("statistic/salaries").then((res) => {
                ctx.commit("setStatSalaries", res.data.salaries);
                // console.log("Salaries", res.data.salaries);

                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findStatSalaries", ctx.rootState.s);
                });
            });
        },
        getStatLoading(ctx) {
            API.GET("statistic/calendar").then((res) => {
                ctx.commit("setStatLoading", res.data.calendar);
                // console.log("Loading", res.data.calendar);

                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findStatLoading", ctx.rootState.s);
                });
            });
        },
        getStatRating(ctx) {
            API.GET("statistic/raiting").then((res) => {
                ctx.commit("setStatRating", res.data);
                // console.log("Rating", res.data);

                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findStatRating", ctx.rootState.s);
                });
            });
        },

        // find values
        findStats(ctx, s) {
            ctx.dispatch("findStatOrders", s);
            ctx.dispatch("findStatServices", s);
            ctx.dispatch("findStatSalaries", s);
            ctx.dispatch("findStatLoading", s);
        },
        findStatOrders(ctx, s) {
            if (!ctx.state.statOrders) {
                return null;
            }
            let list = ctx.state.statOrders;

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => String(item.master_id) == String(s.master));
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.dates && item.dates[0]?.date >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.dates && item.dates[0]?.date <= s.end);
            }

            if (list.length != ctx.state.statOrders?.length) {
                s.clear = true;
            }

            ctx.state.filteredStatOrders = list;
            ctx.dispatch("setSettings", s);
        },
        findStatServices(ctx, s) {
            if (!ctx.state.statServices) {
                return null;
            }
            let list = ctx.state.statServices;

            if (list.length != ctx.state.statServices?.length) {
                s.clear = true;
            }

            ctx.state.filteredStatServices = list;
            ctx.dispatch("setSettings", s);
        },
        findStatSalaries(ctx, s) {
            if (!ctx.state.statSalaries) {
                return null;
            }
            let list = ctx.state.statSalaries;

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => item.uid == s.master);
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.date >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.date <= s.end);
            }

            if (list.length != ctx.state.statSalaries?.length) {
                s.clear = true;
            }

            ctx.state.filteredStatAllSalaries = list;
            ctx.state.filteredStatSalaries = list;
            ctx.dispatch("setSettings", s);
        },
        findStatLoading(ctx, s) {
            if (!ctx.state.statLoading) {
                return null;
            }
            let list = ctx.state.statLoading;

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => item.uid == s.master);
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.date >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.date <= s.end);
            }

            if (list.length != ctx.state.statLoading?.length) {
                s.clear = true;
            }

            ctx.state.filteredStatLoading = list;
            ctx.dispatch("setSettings", s);
        },
        findStatRating(ctx, s) {
            if (!ctx.state.statRating) {
                return null;
            }
            let list = ctx.state.statRating;

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => item.claim?.uid == s.master && item.punctuality?.uid == s.master && item.quality?.uid == s.master);
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.claim?.date >= s.start && item.punctuality?.date >= s.start && item.quality?.date >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.claim?.date <= s.end && item.punctuality?.date <= s.end && item.quality?.date <= s.end);
            }

            if (list.length != ctx.state.statRating?.length) {
                s.clear = true;
            }

            ctx.state.filteredStatRating = list;
            ctx.dispatch("setSettings", s);
        },

        // clear filter
        resetStatFilter(ctx) {
            ctx.rootState.s.month = moment().startOf("month").format("YYYY-MM-DD");
            ctx.commit("setStatOrders", ctx.state.statOrders);
            ctx.commit("setStatServices", ctx.state.statServices);
            ctx.commit("setStatSalaries", ctx.state.statSalaries);
            ctx.commit("setStatLoading", ctx.state.statLoading);
            ctx.commit("setStatRating", ctx.state.statRating);
        },

        pickStatMonth(ctx, date) {
            // ctx.state.statCalendar.current = moment(date).startOf('month').format('YYYY-MM-DD');
            // ctx.state.statCalendar.startMonth = moment(ctx.state.statCalendar.current).startOf('month').format('YYYY-MM-DD');
            // ctx.state.statCalendar.endMonth = moment(ctx.state.statCalendar.current).endOf('month').format('YYYY-MM-DD');
            // ctx.commit('statCalendar', ctx.state.statCalendar);
            ctx.rootState.s.month = moment(date).startOf("month").format("YYYY-MM-DD");
            // ctx.commit('s')
        },

        findRatingDiscipline(ctx, { masterId }) {
            // let m = ctx.rootState.selectMasters;
            let punctuality = ctx.state.filteredStatRating.punctuality?.filter((el) => el.uid == masterId);

            let ratingDiscipline = (10 - (punctuality * 10) / ctx.state.filteredStatOrders.length).toFixed(1);

            if (+ratingDiscipline >= 0) return +ratingDiscipline;
            else return 0;
        },
    },

    getters: {
        statCalendar(state) {
            return state.statCalendar;
        },
        // get filtered states & statisticShow
        statisticShow(state) {
            return state.statisticShow;
        },
        statQualityScore(state) {
            return state.statQualityScore;
        },
        statOrders(state) {
            return state.filteredStatOrders;
        },
        statServices(state) {
            return state.filteredStatServices;
        },
        statAllSalaries(state) {
            return state.filteredStatAllSalaries;
        },
        statSalaries(state) {
            return state.filteredStatSalaries;
        },
        statLoading(state) {
            return state.filteredStatLoading;
        },
        statQuality(state) {
            return state.filteredStatQuality;
        },
        statRating(state) {
            return state.filteredStatRating;
        },

        // counters
        statCountOrders(state) {
            let orders = [];
            if (state.filteredStatOrders?.length > 0) {
                orders[6] = 0;
                orders[8] = 0;
                orders["total"] = 0;
                state.filteredStatOrders?.forEach((elem) => {
                    if (elem.status == 6) {
                        orders[6]++;
                    }
                    if (elem.status == 8) {
                        orders[8]++;
                    }
                    orders["total"] = state.filteredStatOrders.length;
                });
            }
            // console.log("Сортировка Orders", orders);
            return orders;
        },
        statCountDateOrders(state) {
            let dateOrders = [];
            if (state.filteredStatOrders?.length > 0) {
                dateOrders = state.filteredStatOrders.reduce((prev, curr) => {
                    if (!prev[curr.date])
                        prev[curr.date] = {
                            date: curr.date,
                            count: 0,
                        };
                    prev[curr.date]["count"] += 1;
                    return prev;
                }, []);
            }
            // console.log("Сортировка dateOrders", dateOrders);

            return dateOrders;
        },
        statCountServices(state) {
            let topServices = [];
            if (state.filteredStatServices?.length > 0) {
                topServices = state.filteredStatServices.reduce((prev, curr) => {
                    if (!prev[curr.category_id])
                        prev[curr.category_id] = {
                            category_id: curr.category_id,
                            count: 0,
                        };
                    prev[curr.category_id]["count"] += 1;
                    return prev;
                }, []);
            }

            return topServices.sort((a, b) => b.count - a.count).slice(0, 4);
        },
        statCountAllSalaries(state) {
            let count = [];
            if (state.filteredStatAllSalaries?.length > 0) {
                count[1] = 0;
                count[2] = 0;
                count[3] = 0;
                count[4] = 0;
                count[5] = 0;
                count[6] = 0;
                state.filteredStatAllSalaries?.forEach((elem) => {
                    if (elem.type == 1 && elem.status == 1) {
                        count[1] += +elem.sum;
                    }
                    if (elem.type == 1 && elem.status == 8) {
                        count[2] += +elem.sum;
                    }
                    if ((elem.type == 2 || elem.type == 3 || elem.type == 4) && (elem.status == 1 || elem.status == 8)) {
                        count[3] += +elem.sum;
                    }
                    if (elem.type == 7) {
                        count[4] += +elem.sum;
                    }
                    if (elem.type == 9 && elem.status == 1) {
                        count[5] += +elem.sum;
                    }
                    count[6] = count[1] + count[2] + count[3] + count[4] + count[5];
                });
                // console.log("CountedSalaries", count.slice(1));
            }
            return count.slice(1);
        },
        statCountSalaries(state) {
            let salaries = [];
            if (state.filteredStatSalaries?.length > 0) {
                salaries = state.filteredStatSalaries.reduce((prev, curr) => {
                    if (!prev[curr.uid])
                        prev[curr.uid] = {
                            uid: curr.uid,
                            wname: curr.wname,
                            wsecondname: curr.wsecondname,
                            wpicture: curr.wpicture,
                            "type_1-1": 0,
                            "type_1-8": 0,
                            "type_2-3-4": 0,
                            type_7: 0,
                            type_9: 0,
                            total: 0,
                        };
                    if (curr.type == 1 && curr.status == 1) {
                        prev[curr.uid]["type_1-1"] += Number(curr.sum);
                    }
                    if (curr.type == 1 && curr.status == 8) {
                        prev[curr.uid]["type_1-8"] += Number(curr.sum);
                    }
                    if ((curr.type == 2 || curr.type == 3 || curr.type == 4) && (curr.status == 1 || curr.status == 8)) {
                        prev[curr.uid]["type_2-3-4"] += Number(curr.sum);
                    }
                    if (curr.type == 7) {
                        prev[curr.uid]["type_7"] += Number(curr.sum);
                    }
                    if (curr.type == 9) {
                        prev[curr.uid]["type_9"] += Number(curr.sum);
                    }
                    prev[curr.uid]["total"] += Number(curr.sum);
                    return prev;
                }, []);
            }

            return salaries.filter((element) => {
                return element !== null;
            });
        },
        statCountLoading(state) {
            let loading = [];
            if (state.filteredStatLoading?.length > 0) {
                loading = state.filteredStatLoading.reduce((prev, curr) => {
                    if (!prev[curr.status]) {
                        prev[curr.status] = {
                            status: curr.status,
                            total: 0,
                        };
                    }
                    if (curr.status == 1) {
                        prev[curr.status].total += 0.25;
                    }
                    if (curr.status == 3) {
                        prev[curr.status].total += 0.25;
                    }
                    if (curr.status == 0) {
                        prev[curr.status].total += 0.25;
                    }

                    return prev;
                }, []);
            }

            return loading
                .filter((element) => {
                    return element !== null;
                })
                .map((item) => ({
                    ...item,
                    total: Math.round(item.total),
                }));
        },
        getLoading1(state) {
            let loading = [];
            if (state.filteredStatLoading?.length > 0) {
                loading = state.filteredStatLoading.reduce((prev, curr) => {
                    if (!prev[curr.uid])
                        prev[curr.uid] = {
                            uid: curr.uid,
                            wname: curr.wname + " " + curr.wsecondname,
                            total: 0,
                        };
                    if (curr.status == 1) {
                        prev[curr.uid]["total"] += 0.25;
                    }
                    return prev;
                }, []);
            }

            return loading
                .filter((element) => {
                    return element !== null && element.total > 0;
                })
                .map((item) => ({
                    ...item,
                    total: Math.round(item.total),
                }));
        },
        getLoading3(state) {
            let loading = [];
            if (state.filteredStatLoading?.length > 0) {
                loading = state.filteredStatLoading.reduce((prev, curr) => {
                    if (!prev[curr.uid])
                        prev[curr.uid] = {
                            uid: curr.uid,
                            wname: curr.wname + " " + curr.wsecondname,
                            total: 0,
                        };
                    if (curr.status == 3) {
                        prev[curr.uid]["total"] += 0.25;
                    }
                    return prev;
                }, []);
            }

            return loading
                .filter((element) => {
                    return element !== null && element.total > 0;
                })
                .map((item) => ({
                    ...item,
                    total: Math.round(item.total),
                }));
        },
        statCountLoadingStatus(state) {
            let loadingStatus = [];
            if (state.filteredStatLoading?.length > 0) {
                loadingStatus = state.filteredStatLoading.reduce((prev, curr) => {
                    if (!prev[curr.status])
                        prev[curr.status] = {
                            status: curr.status,
                            total: 0,
                        };
                    prev[curr.status]["total"] += 0.25;
                    return prev;
                }, []);
            }

            return loadingStatus
                .filter((element) => {
                    return element.status == 3 || element.status == 1;
                })
                .map((item) => ({
                    ...item,
                    total: Math.round(item.total),
                }));
        },
        statCountQuality(state) {
            let quality = [];
            if (state.filteredStatOrders?.length > 0) {
                quality = state.filteredStatOrders.reduce((prev, curr) => {
                    if (!prev[curr.category])
                        prev[curr.category] = {
                            category: curr.category,
                            claims: 0,
                            reviews: {
                                good: 0,
                                normal: 0,
                                bad: 0,
                            },
                            withReview: 0,
                            withoutReview: 0,
                            allReviews: 0,
                            totalScore: 0,
                        };
                    if (curr.r_id) {
                        prev[curr.category]["claims"] += 1;
                    }
                    if (curr.qcheck == 10) {
                        prev[curr.category].reviews["good"] += 1;
                    }
                    if (curr.qcheck < 10 && curr.qcheck >= 7) {
                        prev[curr.category].reviews["normal"] += 1;
                    }
                    if (curr.qcheck < 7 && curr.qcheck > 0) {
                        prev[curr.category].reviews["bad"] += 1;
                    }
                    if (curr.qcheck) {
                        prev[curr.category].withReview += 1;
                    }
                    if (!curr.qcheck) {
                        prev[curr.category].withoutReview += 1;
                        console.log("withoutReview", curr.id);
                    }
                    if (curr.qcheck > 0) {
                        prev[curr.category].totalScore += Number(curr.qcheck);
                    }
                    if (curr.qcheck || !curr.qcheck) {
                        prev[curr.category].allReviews += 1;
                    }
                    return prev;
                }, []);
            }

            return quality.filter((element) => {
                return element !== null;
            });
        },
        statCountAvgQuality(state) {
            let avgQuality = 0;
            if (state.filteredStatOrders?.length > 0) {
                state.filteredStatOrders.forEach((item) => {
                    if (item.qcheck) {
                        avgQuality += Number(item.qcheck);
                    }
                });
            }

            // console.log("Сортировка avgQuality", (avgQuality / state.filteredStatOrders.length).toFixed(1));

            return (avgQuality / state.filteredStatOrders.length).toFixed(1);
        },
        statCountQualityItem(state) {
            let qualityItem = [];
            if (state.filteredStatOrders?.length > 0) {
                qualityItem = state.filteredStatOrders.reduce((prev, curr) => {
                    if (!prev[curr.id] && curr.category != null)
                        prev[curr.id] = {
                            id: curr.id,
                            iid: curr.iid,
                            category: curr.category,
                            qcheck: curr.qcheck,
                            client: curr.client,
                            master_id: curr.master_id,
                            wname: curr.wname,
                            wsecondname: curr.wsecondname,
                            wpicture: curr.wpicture,
                            date: curr.date,
                            dates: curr.dates,
                        };
                    return prev;
                }, []);
            }

            let resultNormal = ({ category }) =>
                qualityItem.filter((element) => element !== null).filter((item) => item.category == category && item.qcheck < 10 && item.qcheck >= 7);
            let resultBad = ({ category }) =>
                qualityItem.filter((element) => element !== null).filter((item) => item.category == category && item.qcheck < 7 && item.qcheck > 0);
            let resultAllGood = () => qualityItem.filter((element) => element !== null).filter((item) => item.qcheck < 10 && item.qcheck >= 7);
            let resultAllBad = () => qualityItem.filter((element) => element !== null).filter((item) => item.qcheck < 7 && item.qcheck > 0);

            if (state.statQualityScore.category == 1 && state.statQualityScore.score == "normal") {
                return resultNormal({ category: "1" }).sort((a, b) => b.date.localeCompare(a.date));
            }
            if (state.statQualityScore.category == 1 && state.statQualityScore.score == "bad") {
                return resultBad({ category: "1" }).sort((a, b) => b.date.localeCompare(a.date));
            }
            if (state.statQualityScore.category == 2 && state.statQualityScore.score == "normal") {
                return resultNormal({ category: "2" }).sort((a, b) => b.date.localeCompare(a.date));
            }
            if (state.statQualityScore.category == 2 && state.statQualityScore.score == "bad") {
                return resultBad({ category: "2" }).sort((a, b) => b.date.localeCompare(a.date));
            }
            if (state.statQualityScore.category == 3 && state.statQualityScore.score == "normal") {
                return resultNormal({ category: "3" }).sort((a, b) => b.date.localeCompare(a.date));
            }
            if (state.statQualityScore.category == 3 && state.statQualityScore.score == "bad") {
                return resultBad({ category: "3" }).sort((a, b) => b.date.localeCompare(a.date));
            }
            if (state.statQualityScore.category == "all" && state.statQualityScore.score == "normal") {
                return resultAllGood();
            }
            if (state.statQualityScore.category == "all" && state.statQualityScore.score == "bad") {
                return resultAllBad();
            }
        },
        // statCountRating(state) {
        // 	let rating = [];
        // 	let masters = state.rootState.selectMasters;

        // 	if (state.filteredStatRating?.length > 0) {
        // 		rating = state.filteredStatRating.reduce((prev, curr) => {
        // 			if (!prev[curr.uid])
        // 				prev[curr.id] = {
        // 					id: curr.id,
        // 					category: curr.category,
        // 					qcheck: curr.qcheck,
        // 					master_id: curr.master_id,
        // 					wname: curr.wname,
        // 					wsecondname: curr.wsecondname,
        // 					wpicture: curr.wpicture,
        // 					date: curr.date,
        // 					dates: curr.dates,
        // 				};
        // 			return prev;
        // 		}, []);
        // 	}

        // 	return rating;
        // },

        // Standards
        getRatingDiscipline(state) {
            let ratingDiscipline = (10 - (state.filteredStatRating.punctuality?.length * 10) / state.filteredStatOrders.length).toFixed(1);
            // console.log("ratingDiscipline X = ", state.filteredStatRating.punctuality?.length);
            // console.log("ratingDiscipline Y = ", state.filteredStatOrders.length);

            if (+ratingDiscipline >= 0) return +ratingDiscipline;
            else return 0;
        },
        getRatingQuality(state) {
            let ratingQuality = state.filteredStatRating.quality?.reduce((acc, item) => (acc += +item.mcheck), 0);

            // console.log(ratingQuality);
            return Math.round(ratingQuality / state.filteredStatRating.quality?.length);
        },
        getRatingSafety(state) {
            let ratingSafety = (10 - (state.filteredStatRating.claim?.length * 100) / state.filteredStatOrders.length).toFixed(1);
            // console.log("ratingSafety X = ", state.filteredStatRating.claim?.length);
            // console.log("ratingSafety Y = ", state.filteredStatOrders.length);

            if (+ratingSafety >= 0) return +ratingSafety;
            else return 0;
        },
    },
};
