<template>
    <div class="min-h-screen p-8 bg-slate-100">
        <!-- <div class="flex justify-between border-b pb-10 mb-10">
            <input
                type="text"
                class="font-sans block text-sm w-2/3 py-2 px-3 ring-1 ring-slate-900/10 text-slate-500 rounded-lg shadow-sm"
                placeholder="Поиск"
                v-model="find"
                @input="findBot"
            />
            <div class="btn rounded bg-sky-500" @click="Create()">Создать бота</div>
        </div> -->

        <template v-if="s.popup == 'addBot'">
            <div
                v-if="wait"
                class="w-1/2 p-8 min-h-min h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg"
            >
                ПОДКЛЮЧАЕМ!
            </div>

            <div
                v-else
                class="w-1/2 p-8 min-h-min h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg"
            >
                <div class="absolute top-4 right-4" @click="closePopup()"><IcoBig name="delete" :size="5" /></div>
                <h3 class="text-center text-xl mb-4 uppercase">Подключить бот Telegram</h3>
                <hr />
                <div class="flex items-center mt-4 mb-4 text-slate-600">
                    <div class="rounded-full bg-sky-500/25 w-10 h-10 text-lg text-white font-latobold mr-4 flex items-center justify-center">1</div>
                    Откройте бот
                    <a class="text-sky-600" href="https://t.me/BotFather" target="_blank">&nbsp;@BotFather&nbsp;</a>
                    в Telegram и нажмите: /start
                </div>
                <div class="flex items-center mb-5 text-slate-600">
                    <div class="rounded-full bg-sky-500/25 w-10 h-10 text-lg text-white font-latobold mr-4 flex items-center justify-center">2</div>
                    Напишите боту: /newbot и следуйте инструкции
                </div>
                <div class="flex items-center mb-10 text-slate-600">
                    <div
                        class="rounded-full flex-shrink-0 bg-sky-500/25 w-10 h-10 text-lg text-white font-latobold mr-4 flex items-center justify-center"
                    >
                        3
                    </div>
                    <div>
                        Когда увидите подобный токен:
                        <span class="text-red-800 block">15237204:AGbEQewU1K8RkWxtKnw36sg9EM</span>
                        Скопируйте его и вставьте сюда
                    </div>
                </div>
                <div class="flex justify-between">
                    <input
                        type="text"
                        class="font-sans block text-sm w-1/4 mr-3 py-2 px-3 ring-1 ring-slate-900/10 text-slate-500 rounded-lg shadow-sm"
                        placeholder="Имя Бота"
                        v-model="name"
                    />
                    <input
                        type="text"
                        class="font-sans block text-sm w-2/3 mr-3 py-2 px-3 ring-1 ring-slate-900/10 text-slate-500 rounded-lg shadow-sm"
                        placeholder="Token Telegram"
                        v-model="token"
                    />
                    <div class="btn rounded bg-sky-500" @click="addBot({ name: name, tl_token: token }), (wait = true)">Подключить</div>
                </div>
            </div>
        </template>
        <div v-if="s.popup" @click="closePopup()" class="fixed bg-sky-900 z-10 w-screen h-screen top-0 left-0 opacity-50" id="overlay"></div>

        <div class="grid grid-cols-6 gap-8 max-l:grid-cols-2 max-l:gap-4">
            <template v-if="bots?.length > 0">
                <transition-group appear :css="false" @before-enter="beforeEnter" @enter="enter" @leave="leave" name="list">
                    <div v-for="(bot, index) in filteredBots" :key="bot.id" :data-index="index">
                        <div class="mes group cursor-pointer justify-center items-center" @click="pickBot(bot)">
                            <h3 class="text-center text-lg group-hover:text-sky-500 transition">{{ bot.name }}</h3>
                        </div>
                    </div>
                </transition-group>
            </template>

            <div class="mes group cursor-pointer opacity-50 hover:opacity-100 justify-center items-center" @click="s.popup = 'addBot'">
                <IcoBig name="plus" :size="8" />
                <div class="text-xs text-center mt-4">Создать бота</div>
            </div>
        </div>
        <!-- <div v-else class="flex justify-center items-center">
                <svg role="status" class="w-10 h-10 text-gray-300 animate-spin fill-sky-500" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    ></path>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    ></path>
                </svg>
            </div> -->
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";

import gsap from "gsap";

export default {
    name: "BotList",

    data() {
        return {
            find: "",
            createFlag: false,
            name: "",
            token: "",
            wait: false,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "setView"]),
        ...mapState(useBotStore, ["bots", "getBots", "findBot", "pickBot", "addBot"]),

        filteredBots() {
            var vm = this;
            return this.bots.filter((item) => {
                return item.name.toLowerCase().indexOf(vm.find.toLowerCase()) !== -1;
            });
        },
    },

    methods: {
        beforeEnter(el) {
            el.style.opacity = 0;
            el.style.height = 0;
        },
        enter(el, done) {
            gsap.to(el, {
                opacity: 1,
                height: el.firstChild.offsetHeight + "px",
                delay: el.dataset.index * 0.01,
                onComplete: done,
            });
        },
        leave(el, done) {
            gsap.to(el, {
                opacity: 0,
                height: 0,
                delay: el.dataset.index * 0.01,
                onComplete: done,
            });
        },
    },

    mounted() {
        this.getBots();
    },
};
</script>
