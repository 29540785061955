import { useAuthorStore as authorStore } from "@/store/author";

const authService = {
    async isLogged() {
        console.log("auth isLogged", authorStore().author?.id);

        if (authorStore().author?.id && authorStore().menu && !!localStorage.getItem("AUTH")) return true;

        await authorStore().getAuthor();

        if (authorStore().author?.id && authorStore().menu && !!localStorage.getItem("AUTH")) return true;

        return false;
    },
};

export default authService;
