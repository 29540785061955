<template>
    <span v-if="pic" class="block w-11 h-11 rounded-full overflow-hidden">
        <img :src="'https://api.tahocrm.ru/uploads/users/' + pic" />
    </span>
    <span v-else class="text-white text-xl w-11 h-11 leading-none rounded-full flex items-center justify-center">
        {{ name?.slice(0, 1) }}
    </span>
</template>

<script>
export default {
    name: "IcoPic",
    props: {
        pic: String,
        name: String,
    },
};
</script>
