import { createStore } from "vuex";
import API from "../services/api";
import moment from "moment";

import router from "@/services/router";
import bot from "./modules/bot";
import group from "./modules/group";
import message from "./modules/message";
import author from "./modules/author";
import timer from "./modules/timer";
import statistic from "./modules/statistic";
import user from "./modules/user";

export default createStore({
    modules: {
        author,
        bot,
        group,
        message,
        timer,
        statistic,
        user,
    },

    state: {
        alert: {},
        comments: [],
        s: {
            view: "cart",
            status: null,
            findme: null,
            category: null,
            type: null,
            manager: null,
            master: null,
            month: moment().startOf("month").format("YYYY-MM-DD"),
            start: null,
            end: null,
            popup: null,
            back: null,
            backpop: null,
            clear: null,
            loader: null,
        },
        popup: false,
        loadForm: true,
        progressBar: "",
        result: "",
    },

    mutations: {
        setAlert: (state, alert) => {
            state.alert = alert;
        },
        settings: (state, s) => {
            state.s = s;
        },
        setProgressBar: (state, progressBar) => {
            state.progressBar = progressBar;
        },
        setResult: (state, result) => {
            state.result = result;
        },
    },

    actions: {
        clearAlert(ctx) {
            ctx.commit("setAlert", null);
        },

        setSettings(ctx, s) {
            localStorage.setItem("s", JSON.stringify(s));
            ctx.commit("settings", s);
        },

        toggleListView(ctx) {
            if (ctx.state.s.view == "cart") {
                ctx.state.s.view = "list";
            } else {
                ctx.state.s.view = "cart";
            }
            ctx.dispatch("setSettings", ctx.state.s);
        },

        loadSettings(ctx) {
            // console.log("loadSettings", performance.now());
            let s = localStorage.getItem("s");
            if (s) {
                ctx.commit("settings", JSON.parse(s));
            }
        },

        clearSettings(ctx) {
            localStorage.removeItem("s");
            let s = {
                status: null,
                findme: null,
                category: 0,
                type: 0,
                manager: 0,
                master: 0,
                student: 0,
                month: moment().format("YYYY-MM-DD"),
                start: null,
                end: null,
                popup: null,
                back: null,
                backpop: null,
                clear: null,
                loader: null,
            };
            ctx.dispatch("setSettings", s);
        },

        setLoader(ctx, loader) {
            ctx.state.s.loader = loader;
            ctx.dispatch("setSettings", ctx.state.s);
        },

        showPopup(ctx, popup = false) {
            ctx.state.s.popup = popup;
            ctx.dispatch("setSettings", ctx.state.s);
        },

        closePopup(ctx) {
            ctx.dispatch("showPopup");
        },

        // вернуть обратно и открыть popup если нужно (из backpop)
        goBack(ctx) {
            if (ctx.state.s.back) {
                ctx.state.s.popup = ctx.state.s.backpop;
                router.push(ctx.state.s.back);
                ctx.state.s.back = null;
                ctx.state.s.backpop = null;
                ctx.dispatch("setSendings", ctx.state.s);
            } else {
                ctx.state.s.popup = null;
                ctx.dispatch("setSendings", ctx.state.s);
            }
        },

        pluralize(n, words) {
            let idx;
            if (n % 10 === 1 && n % 100 !== 11) {
                idx = 0; // many
            } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
                idx = 1; // few
            } else {
                idx = 2; // one
            }
            return words[idx] || "";
        },

        pickMonth(ctx, date) {
            ctx.state.s.month = moment(date).format("YYYY-MM-DD");
            ctx.commit("sendings", ctx.state.s);
        },

        setTab(ctx, tab) {
            ctx.state.s.tab = tab;
            localStorage.setItem("s", JSON.stringify(ctx.state.s));
            ctx.commit("sendings", ctx.state.s);
        },

        getComments(ctx, formData) {
            API.GET("/ajax/comments", formData).then((res) => {
                let key = formData.type + formData.id;
                ctx.state.comments[key] = res.data;
                ctx.commit("setComments", ctx.state.comments);
            });
        },

        addComment(ctx, data) {
            console.log("formData key:", data.key);
            API.POST("/ajax/add-comment", data.formData).then((res) => {
                ctx.state.comments[data.key] = res.data;
                ctx.commit("setComments", ctx.state.comments);
            });
        },

        delComment(ctx, data) {
            ctx.state.comments[data.key] = ctx.state.comments[data.key].filter((t) => t !== data.comment);
            API.POST("/ajax/del-comment", data.formData);
        },
    },

    getters: {
        alert(state) {
            return state.alert;
        },
        s(state) {
            return state.s;
        },
        popup(state) {
            return state.popup;
        },
        loadForm(state) {
            return state.loadForm;
        },
        progressBar(state) {
            return state.progressBar;
        },
        result(state) {
            return state.result;
        },
    },
});
