<template>
    <div class="text-center">
        <div v-if="loadForm">
            <input
                type="file"
                :id="form"
                class="absolute invisible opacity-0"
                multiple
                @change="Upload({ e: $event, item: item, type: this.type, id: this.id, status: this.status })"
            />
            <label :for="form" class="flex items-center text-[12px] bg-emerald-500 text-white font-latobold cursor-pointer rounded-md w-full h-10">
                <span class="flex items-center justify-center h-10 w-10 mr-4 border-r-[1px] border-white">
                    <svg class="fill-white w-4 h-4"><use xlink:href="@/assets/i.svg#download"></use></svg>
                </span>
                <span>{{ name }}</span>
            </label>
        </div>
        <div v-else class="loader opacity1 pr">
            <img src="https://api.tahocrm.ru/images/loader.svg" width="44" />
            <!-- {{ progressBar }}% -->
        </div>
        <div v-if="result && result.length > 0" class="text-xs text-red-500">{{ result }}</div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "uploadImg",

    props: {
        item: Object,
        id: Number,
        type: String,
        status: Number,
        name: String,
    },

    computed: {
        ...mapGetters(["loadForm", "progressBar", "result"]),

        form() {
            return "pictureform" + this.status;
        },
    },

    methods: {
        ...mapActions(["Upload"]),
    },
};
</script>
