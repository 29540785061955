<template>
    <!-- Один элемент (пользователь) из списка -->
    <div class="grid grid-cols-8 items-center gap-2 bg-white/50 py-1 px-2 rounded text-xs cursor-pointer hover:bg-white">
        <img v-if="user.picture" :src="'https://yii.botteach.ru/users/mini/' + user.picture" class="rounded-full w-8 h-8 shrink-0" />
        <div v-else class="flex justify-center items-center rounded-full w-8 h-8 shrink-0 bg-slate-200 text-slate-500">
            {{ user.name?.slice(0, 1) }}
        </div>
        <div class="flex items-center text-sm col-span-4 h-8 overflow-hidden leading-5">{{ user.name }} {{ user.secondname }}</div>
        <div class="">{{ roles[user.role] }}</div>
        <div class="circle" :class="{ '!bg-teal-500': user.status == 1 }"></div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";

export default {
    name: "ItemList",

    props: {
        user: {
            type: Object,
        },
    },

    computed: {
        ...mapState(useMainStore, ["roles"]),
    },
};
</script>
