<template>
    <h3 class="text-lg text-center mb-2">Библиотека картинок, аудио и видео</h3>

    <hr />

    <div class="flex items-center justify-between">
        <input
            type="text"
            class="block text-sm w-full py-2 px-3 my-4 mr-4 ring-1 ring-slate-900/10 text-slate-500 rounded"
            placeholder="Поиск"
            v-model="findme"
            @input="find()"
        />

        <div
            @click="(this.type = 0), find()"
            class="flex p-2 rounded text-gray-400 cursor-pointer items-center hover:text-sky-500 hover:border-sky-500"
        >
            All
        </div>

        <div
            @click="(this.type = 1), find()"
            class="flex p-2 rounded text-gray-400 cursor-pointer items-center hover:text-sky-500 hover:border-sky-500"
        >
            <IcoBig name="image" :size="5" />
        </div>
        <div
            @click="(this.type = 3), find()"
            class="flex p-2 rounded text-gray-400 cursor-pointer items-center hover:text-sky-500 hover:border-sky-500"
        >
            <IcoBig name="audio" :size="5" />
        </div>
        <div
            @click="(this.type = 7), find()"
            class="flex p-2 mr-4 rounded text-gray-400 cursor-pointer items-center hover:text-sky-500 hover:border-sky-500"
        >
            <IcoBig name="video" :size="5" />
        </div>

        <div>
            <input type="file" id="image" class="absolute w-0 h-0 invisible opacity-0" multiple @change="uploadImg({ e: $event })" />

            <label
                for="image"
                class="flex p-1.5 rounded text-gray-400 border border-dashed border-gray-300 cursor-pointer items-center justify-start hover:text-sky-500 hover:border-sky-500"
            >
                <IcoBig name="upload" :size="5" />
                <div class="text-base ml-3">Загрузить</div>
            </label>
        </div>
    </div>

    <hr />

    <div v-if="list?.length > 0" class="max-h-96 h-fit overflow-auto grid grid-cols-4 gap-3">
        <div v-for="file in list.slice(0, 100)" :key="file">
            <div
                class="flex justify-between bg-origin-border py-2 rounded"
                :class="{ 'bg-sky-200': message.tl === file.id }"
                @click.stop="pickFile(file)"
            >
                <div class="flex items-center">
                    <div v-if="file.type == 1">
                        <img :src="'https://yii.botteach.ru/uploads/' + bot.id + '/mini/' + file.name" alt="" class="w-8 h-8" />
                        <!-- <IcoBig name="image" :size="4" /> -->
                    </div>
                    <div v-if="file.type == 3"><IcoBig name="audio" :size="4" /></div>
                    <div v-if="file.type == 7">
                        <img :src="'https://yii.botteach.ru/uploads/' + bot.id + '/mini/' + file.name" alt="" class="w-8 h-8" />
                        <!-- <IcoBig name="video" :size="4" /> -->
                    </div>
                    <div class="mx-2">
                        {{ file.description?.slice(0, 20) }}
                        <div class="block text-[9px] text-gray-400">{{ file.name }}</div>
                    </div>
                </div>
                <!-- <div class="align-middle self-center justify-self-end" @click.stop="delVideo(file.id)">
                    <IcoBig name="trash" :size="4" />
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";
import { useMessageStore } from "@/store/message";

export default {
    name: "FileList",

    data() {
        return {
            findme: "",
            type: 0,
            list: [],
        };
    },

    watch: {
        files: {
            handler() {
                this.mount();
            },
            deep: true,
        },
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useBotStore, ["bot", "files", "getFiles", "uploadImg"]),
        ...mapState(useMessageStore, ["message", "saveMessage"]),

        student() {
            return this.meStudents.filter((item) => item.bot_id == this.bot.id);
        },
    },

    methods: {
        mount() {
            if (this.files) {
                this.list = this.files;
            }
        },

        find() {
            this.list = this.files;

            if (this.type > 0) {
                this.list = this.list.filter((item) => item.type == this.type);
            }

            if (this.findme != "" && this.findme != undefined) {
                this.list = this.list.filter((item) => {
                    let name = null;
                    let desc = null;
                    // if (item.name) {
                    //     name = item.name.toLowerCase().indexOf(this.findme.toLowerCase()) !== -1;
                    // }
                    if (item.description) {
                        desc = item.description.toLowerCase().indexOf(this.findme.toLowerCase()) !== -1;
                    }
                    if (name || desc) {
                        return true;
                    }
                    return false;
                });
            }
        },

        pickFile(file) {
            this.message.file_id = file.id;
            this.message.file = file;
            this.saveMessage(this.message);
            this.closePopup();
        },
    },
    mounted() {
        this.getFiles();
    },
};
</script>
