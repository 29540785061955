<template>
    <div v-if="group?.id > 0">
        <!-- <div v-if="student?.length <= 0" class="absolute top-20 left-1/2 -translate-x-1/2 h-fit w-1/3 rounded z-50 p-8 bg-white text-center">
            <h2 class="text-2xl mb-2">Добавьтесь в бота</h2>
            <p>1. Откройте в телеграмме бота</p>
            <p>2. Нажмите в нем кнопку <span class="text-sky-500 font-latobold">/start</span></p>
            <div
                @click="getBot()"
                class="w-1/2 my-4 mx-auto text-base bg-teal-500 hover:bg-teal-400 text-white p-2 cursor-pointer rounded text-center"
            >
                Готово
            </div>
        </div>
        <div v-if="student?.length <= 0" @click="closePopup()" class="fixed bg-sky-900 z-10 w-screen h-screen top-0 left-0 opacity-50"></div> -->

        <div class="flex h-screen max-h-screen max-l:block">
            <div class="relative pb-10 w-[440px] bg-sky-100 overflow-y-scroll max-l:h-100 max-l:w-full max-l:overflow-hidden">
                <div class="sticky top-0 left-0 right-0 p-4 z-50 flex items-center font-semibold bg-sky-800/50 backdrop-blur-sm shadow">
                    <div class="relative w-full">
                        <input
                            type="text"
                            class="block text-sm w-full py-2 px-3 text-sky-600 rounded shadow-inner"
                            placeholder="Поиск сообщения"
                            v-model="s.find"
                            @input="findM(s.find)"
                        />
                        <div v-if="s.find?.length > 0" class="group absolute top-2 right-2" @click.stop="clearFindM(), (s.find = null)">
                            <IcoBig name="delete" :size="5" />
                        </div>
                    </div>
                </div>

                <div class="h-fit p-5"><MessageList /></div>
            </div>

            <div v-if="message" class="flex-1 flex gap-5 p-5 relative bg-white/50 max-l:block"><MessageEdit /></div>

            <div v-if="s.popup == 'btn'" class="absolute top-20 right-20 h-fit w-[300px] rounded z-50 p-8 bg-white"><BtnPopup /></div>

            <div v-if="s.popup == 'life'" class="absolute top-20 right-20 h-fit w-[300px] rounded z-50 p-8 bg-white"><LifeList /></div>

            <div
                v-if="s.popup == 'files'"
                class="w-5/6 transition duration-200 min-h-min fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto right-20 h-fit rounded z-50 p-8 bg-white"
            >
                <FileList />
            </div>

            <div
                v-if="['modules', 'types', 'variables', 'themes', 'segments'].includes(s.popup)"
                class="absolute top-20 right-20 max-h-2/3 h-fit w-[300px] rounded z-50 px-8 py-4 bg-white overflow-hidden"
            >
                <ParamsList />
            </div>

            <div v-if="s.popup == 'goto'" class="absolute top-20 right-20 max-h-2/3 h-fit w-[300px] rounded z-50 px-8 py-4 bg-white overflow-hidden">
                <GotoList />
            </div>

            <div
                v-if="s.popup == 'students'"
                class="w-5/6 min-h-min fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto right-20 h-fit rounded z-50 p-8 bg-white"
            >
                <StudentsBot />
            </div>

            <div
                v-if="s.popup == 'generate'"
                class="w-5/6 h-[calc(100vh-120px)] fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto right-20 rounded z-50 p-8 bg-white"
            >
                <MessageGenerate />
            </div>

            <div v-if="s.popup" @click="closePopup()" class="fixed bg-sky-900 z-10 w-screen h-screen top-0 left-0 opacity-50" id="overlay"></div>
        </div>
    </div>

    <div v-else class="flex justify-center items-center"><IcoSpin /></div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";
import { useGroupStore } from "@/store/group";
import { useMessageStore } from "@/store/message";

import IcoSpin from "../components/ui/IcoSpin.vue";
import IcoBig from "../components/ui/IcoBig.vue";
import BtnPopup from "../components/popup/BtnPopup.vue";

import MessageList from "../components/Message/MessageList.vue";
import MessageEdit from "../components/Message/MessageEdit.vue";
import MessageGenerate from "../components/Message/MessageGenerate.vue";
import FileList from "../components/Message/FileList.vue";
import LifeList from "../components/Message/LifeList.vue";
import ParamsList from "../components/Message/ParamsList.vue";
import GotoList from "../components/Message/GotoList.vue";
import StudentsBot from "../components/Bot/StudentsBot.vue";

export default {
    name: "BotView",

    components: {
        IcoSpin,
        IcoBig,

        MessageList,
        MessageEdit,
        MessageGenerate,
        BtnPopup,
        FileList,
        LifeList,
        ParamsList,
        GotoList,
        StudentsBot,
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "setView"]),
        ...mapState(useBotStore, ["bot", "getBot"]),
        ...mapState(useGroupStore, ["groups", "group"]),
        ...mapState(useMessageStore, ["messages", "message"]),

        student() {
            return this.meStudents.filter((item) => item.bot_id == this.bot.id);
        },
    },

    methods: {
        // sortEnd(e) {
        //     // console.log("this", this);
        //     // console.log("e", e);
        //     // console.log("e", e.originalEvent.path);
        //     let iiid = 0;
        //     e.originalEvent.path.forEach((element) => {
        //         if (parseInt(element.id) > 0) {
        //             iiid = element.id;
        //             return;
        //         }
        //     });
        //     console.log("iiid", iiid);
        // },

        setClass(btn) {
            return btn[2];
        },

        // pickBtn(btn) {
        //     console.log(btn);
        //     this.popup = "btn";
        //     this.btn = btn;
        // },
    },

    mounted() {
        this.getBot();
    },
};
</script>
<style>
.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}
</style>
