import { defineStore } from "pinia";
import moment from "moment";

import { useAuthorStore as authorStore } from "./author";
import { useBotStore as botStore } from "./bot";
// import { useMessageStore as messageStore } from "./message";

export const useMainStore = defineStore("main", {
    state: () => ({
        alert: {},
        s: {
            find: null,
            bot_id: null,
            group_id: null,
            user_id: null,
            type: null,
            status: null,
            month: moment().startOf("month").format("YYYY-MM-DD"),
            start: null,
            end: null,
            popup: null,
            clear: null,
            view: null,
            hidden: false,
        },
        roles: {
            1: "Viewer", // только смотрит активные сообщения в боте
            3: "Editor", // может менять текст и настройки сообщений
            5: "Author", // может удалять и создавать сообщения
            7: "Producer", // может только смотреть статистику
            10: "Admin", // может все + приглашать других + менять права
        },
        month: ["", "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        monthMin: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
        weekday: ["", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
        routes: [],
    }),

    actions: {
        setAlert(alert) {
            this.alert = alert;
        },

        setSettings(s) {
            this.s = s;
            localStorage.setItem("s", JSON.stringify(s));
        },

        // загрузить данные из localStorage
        loadData() {
            const author = localStorage.getItem("author");
            const menu = localStorage.getItem("menu");
            const bot = localStorage.getItem("bot");
            // const message = localStorage.getItem("message");
            // const message_id = localStorage.getItem("message_id");
            const s = localStorage.getItem("s");

            // console.log("message_id", message_id);

            if (author && author !== "undefined") authorStore().author = JSON.parse(author);
            if (menu && menu !== "undefined") authorStore().menu = JSON.parse(menu);
            if (bot && bot !== "undefined") botStore().bot = JSON.parse(bot);
            // if (message && message !== "undefined") messageStore().message = JSON.parse(message);
            // if (parseInt(message_id)) messageStore().message = messageStore().messages.filter((m) => m.id == message_id)[0];
            if (s && s !== "undefined") this.s = JSON.parse(s);
        },

        clearSettings() {
            this.s.find = null;
            this.s.group_id = null;
            this.s.user_id = null;
            this.s.type = null;
            this.s.status = null;
            this.s.month = moment().startOf("month").format("YYYY-MM-DD");
            this.s.start = null;
            this.s.end = null;
            this.s.clear = null;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setPopup(popup) {
            this.s.popup = popup;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        closePopup() {
            this.s.popup = false;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setView(view) {
            this.s.view = view;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setHidden() {
            this.s.hidden = !this.s.hidden;
            localStorage.setItem("s", JSON.stringify(this.s));
        },
    },
});
