import IconLoader from "@/components/IconLoader";
import IcoSpin from "@/components/ui/IcoSpin.vue";
import IcoBig from "@/components/ui/IcoBig.vue";
import IcoStatus from "@/components/ui/IcoStatus";
import IcoPic from "@/components/ui/IcoPic";
import IcoPicSmall from "@/components/ui/IcoPicSmall";
import IcoImg from "@/components/ui/IcoImg";
import UploadImg from "@/components/ui/UploadImg";

import IconClose from "@/components/icons/IconClose";
import IconDelete from "@/components/icons/IconDelete";
import IconEdit from "@/components/icons/IconEdit";
import IconEye from "@/components/icons/IconEye";
import IconEyeClose from "@/components/icons/IconEyeClose";
import IconHome from "@/components/icons/IconHome";
import IconLink from "@/components/icons/IconLink";
import IconMore from "@/components/icons/IconMore";
import IconOk from "@/components/icons/IconOk";
import IconPlus from "@/components/icons/IconPlus";
import IconProtfolio from "@/components/icons/IconProtfolio";
import IconRe from "@/components/icons/IconRe";
import IconRound from "@/components/icons/IconRound";
import IconSetting from "@/components/icons/IconSetting";
import IconText from "@/components/icons/IconText";
import IconTimer from "@/components/icons/IconTimer";
import IconUser from "@/components/icons/IconUser";

export default [
    IcoSpin,
    IcoBig,
    IconLoader,
    IcoStatus,
    IcoPic,
    IcoPicSmall,
    IcoImg,
    UploadImg,

    IconClose,
    IconDelete,
    IconEdit,
    IconEye,
    IconEyeClose,
    IconHome,
    IconLink,
    IconMore,
    IconOk,
    IconPlus,
    IconProtfolio,
    IconRe,
    IconRound,
    IconSetting,
    IconText,
    IconTimer,
    IconUser,
];
