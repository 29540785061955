<template>
    <div class="min-h-screen p-8 bg-slate-100">
        <div class="bg-white rounded p-8">
            <div v-if="!bot.tl_link" class="flex flex-col items-center gap-2 bg-pink-200 text-pink-800 text-center mb-4 p-4 rounded">
                В настройках добавьте ссылку на бота в формате https://t.me/my_bot
                <!-- <input type="text" class="input w-96" /> -->
            </div>

            <span class="title">UTM ССЫЛКИ</span>

            <div class="grid grid-cols-8 items-center bg-white gap-4 p-1 mt-4 text-xs text-slate-500 border-b border-slate-200">
                <div>source</div>
                <div>medium</div>
                <div>campaign</div>
                <div>content</div>
                <div>term</div>
                <div>ССЫЛКА</div>
                <div>ЛИДЫ</div>
                <div class="text-right">СТАТУС</div>
            </div>

            <div v-for="item in utms" :key="item.id" class="grid grid-cols-8 items-center bg-white gap-4 p-1 text-sm border-b border-slate-200">
                <!-- <div class="text-slate-500">{{ item.id }}</div> -->

                <div>{{ item.source }}</div>
                <div>{{ item.medium }}</div>
                <div>{{ item.campaign }}</div>
                <div>{{ item.content }}</div>
                <div>{{ item.term }}</div>
                <div @click="copyToClipboard(bot.tl_link + '?start=' + item.link)" class="hover:text-cyan-600 cursor-copy">
                    {{ item.link }}
                </div>

                <div>{{ item.regs }}</div>

                <div class="text-right text-slate-500">{{ item.status }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";
import { useAdStore } from "@/store/ad";

export default {
    name: "UtmList",

    data() {
        return {
            find: "",
            createFlag: false,
            name: "",
            token: "",
            wait: false,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "setView", "setAlert"]),
        ...mapState(useBotStore, ["bot"]),
        ...mapState(useAdStore, ["getUtms", "utms"]),
    },

    methods: {
        copyToClipboard(text) {
            const el = document.createElement("textarea");
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);

            this.setAlert({ msg: "Скопировано в буфер", type: "success" });

            // Можно также использовать более современный API для копирования в буфер обмена
            // navigator.clipboard
            //     .writeText(text)
            //     .then(() => {
            //         console.log("Текст скопирован успешно");
            //     })
            //     .catch((err) => {
            //         console.error("Ошибка копирования текста", err);
            //     });
        },
    },

    mounted() {
        this.getUtms();
    },
};
</script>
