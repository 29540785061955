<template>
    <h3 class="text-lg text-center mb-2">Пользователи бота</h3>

    <hr />

    <div class="flex items-center justify-between">
        <input
            type="text"
            class="block text-sm w-full py-2 px-3 my-4 mr-4 ring-1 ring-slate-900/10 text-slate-500 rounded"
            placeholder="Поиск"
            v-model="findme"
            @input="find()"
        />

        <div
            @click="(this.type = 0), find()"
            class="flex p-2 rounded text-gray-400 cursor-pointer items-center hover:text-sky-500 hover:border-sky-500"
        >
            All
        </div>
    </div>

    <hr />

    <div v-if="students?.length > 0" class="max-h-96 h-fit overflow-auto grid grid-cols-4 gap-3">
        <div v-for="student in students.slice(0, 100)" :key="student">
            <div class="flex justify-between bg-origin-border py-2 rounded">
                <div class="flex items-center cursor-pointer" @click="send({ net: 'tl', student_id: student.student_id })">
                    <div v-if="student.picture">
                        <img :src="'https://yii.botteach.ru/users/mini/' + student.picture" class="w-8 h-8 rounded-full" />
                    </div>
                    <div class="mx-2">{{ student.name }}</div>
                    <div v-if="student.net == 2"><IcoBig name="tl" :size="4" /></div>
                    <div v-if="student.net == 1"><IcoBig name="vk" :size="4" /></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";
import { useMessageStore } from "@/store/message";
import { useUserStore } from "@/store/user";

export default {
    name: "StudentsBot",

    data() {
        return {
            findme: "",
            type: 0,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useBotStore, ["bot"]),
        ...mapState(useMessageStore, ["send"]),
        ...mapState(useUserStore, ["students", "getStudentsInBot"]),

        student() {
            return this.meStudents.filter((item) => item.bot_id == this.bot.id);
        },
    },

    methods: {
        find() {
            this.list = this.students;

            if (this.type > 0) {
                this.list = this.list.filter((item) => item.bot_id == this.bot.id);
            }

            if (this.findme != "" && this.findme != undefined) {
                this.list = this.list.filter((item) => {
                    let name = null;
                    let secondname = null;
                    if (item.name) {
                        name = item.name.toLowerCase().indexOf(this.findme.toLowerCase()) !== -1;
                    }
                    if (item.secondname) {
                        secondname = item.secondname.toLowerCase().indexOf(this.findme.toLowerCase()) !== -1;
                    }
                    if (name || secondname) {
                        return true;
                    }
                    return false;
                });
            }
        },
    },
    mounted() {
        this.getStudentsInBot();
    },
};
</script>
