import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";
import { useBotStore as botStore } from "./bot";

export const useTimerStore = defineStore("timer", {
    state: () => ({
        timersAll: [],
        timers: [],
        timer: {},
    }),

    actions: {
        async getTimers() {
            const res = await API.GET("send/timer/list", { bot_id: botStore().bot.id });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            // if (res.data.groups) this.setGroups(res.data.groups);
            // if (res.data.types) this.setTypes(res.data.types);
            // if (res.data.modules) this.setModules(res.data.modules);
            // if (res.data.segments) this.setSegments(res.data.segments);

            if (res.data.timers?.length > 0) {
                res.data.timers.push({ status: 100, name: "таймер" });
                this.timersAll = res.data.timers;
                this.timers = res.data.timers;
                this.showAll();
            }
        },

        showAll() {
            const timer = JSON.parse(localStorage.getItem("timer"));
            if (timer) this.timer = timer;
        },

        pickTimer(timer) {
            this.timer = timer;
            localStorage.setItem("timer", JSON.stringify(this.timer));
        },

        async addTimer() {
            const formData = new FormData();
            formData.append("bot_id", botStore().bot.id);

            const res = await API.POST("send/timer/add", formData);
            console.log("addTimer", res.data);
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            if (res.data.timers?.length > 0) {
                if (res.data.timer) this.pickTimer(res.data.timer);
                this.timersAll = res.data.timers;
                this.timers = res.data.timers;
            }
        },

        async sortTimer() {
            console.log("sortTimer");
            const sort = this.timersAll.map((t) => t.id);

            console.log(sort);

            const formData = new FormData();
            formData.append("bot_id", botStore().bot.id);
            formData.append("sort", sort);

            const res = await API.POST("send/timer/sort", formData);
            console.log("sortTimer", res.data);
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            if (res.data.timers?.length > 0) {
                this.timersAll = res.data.timers;
                this.timers = res.data.timers;
            }
        },

        async saveTimer(timer) {
            this.timer = timer;
            localStorage.setItem("timer", JSON.stringify(this.timer));

            const formData = new FormData();
            Object.keys(timer).forEach((key) => {
                formData.append(key, timer[key]);
            });

            const res = await API.POST("send/timer/save", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            this.timersAll.forEach((item, i) => {
                if (item.id === timer.id) this.timersAll[i] = timer;
            });

            this.timers.forEach((item, i) => {
                if (item.id === timer.id) this.timers[i] = timer;
            });
        },

        async delTimer(timer) {
            this.timersAll = this.timersAll.filter((item) => item.id !== timer.id);
            this.timers = this.timers.filter((item) => item.id !== timer.id);

            this.timer = null;

            const formData = new FormData();
            formData.append("bot_id", botStore().bot.id);
            formData.append("id", timer.id);

            const res = await API.POST("send/timer/delete", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
        },

        async setTimerParam(data) {
            if (!this.timer?.id) return;

            // if (type == "segments") this.timer.segment_id = data.param_id;
            if (data.type == "groups") this.timer.group_id = data.param_id;
            if (data.type == "types") this.timer.type_id = data.param_id;
            if (data.type == "modules") this.timer.module_id = data.param_id;

            localStorage.setItem("timer", JSON.stringify(this.timer));

            let formData = new FormData();
            formData.append("bot_id", this.timer.bot_id);
            formData.append("timer_id", this.timer.id);
            formData.append("param_id", data.param_id);
            formData.append("type", data.type);

            mainStore().closePopup();

            const res = await API.POST("send/timer/set-param", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            this.timersAll.forEach((item, i) => {
                if (item.id === this.timer.id) this.timersAll[i] = this.timer;
            });

            this.timers.forEach((item, i) => {
                if (item.id === this.timer.id) this.timers[i] = this.timer;
            });
        },
    },
});
