<template>
    <h3 class="text-lg text-center mb-2">Награда</h3>

    <hr />
    <div class="grid grid-cols-2 gap-2 mt-4 items-center" v-if="message">
        <p class="text-base mr-4">Жизни</p>
        <input
            type="text"
            class="block text-sm w-full py-2 px-3 mr-4 ring-1 ring-slate-900/10 text-slate-500 rounded"
            placeholder="Жизни"
            v-model="message.life"
        />

        <p class="text-base mr-4">Энергия</p>
        <input
            type="text"
            class="block text-sm w-full py-2 px-3 mr-4 ring-1 ring-slate-900/10 text-slate-500 rounded"
            placeholder="Энергия"
            v-model="message.energy"
        />

        <p class="text-base mr-4">Баллы</p>
        <input
            type="text"
            class="block text-sm w-full py-2 px-3 mr-4 ring-1 ring-slate-900/10 text-slate-500 rounded"
            placeholder="Баллы"
            v-model="message.pointy"
        />

        <p class="text-base mr-4">Дни</p>
        <input
            type="text"
            class="block text-sm w-full py-2 px-3 mr-4 ring-1 ring-slate-900/10 text-slate-500 rounded"
            placeholder="Дни"
            v-model="message.day"
        />

        <div class="btn bg-sky-500 col-span-2 mt-4" @click="saveMessage(message), closePopup()">Сохранить</div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useMessageStore } from "@/store/message";

export default {
    name: "LifeList",

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "setView"]),
        ...mapState(useMessageStore, ["message", "saveMessage"]),
    },
};
</script>
