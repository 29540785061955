<template>
    <div class="flex h-screen min-h-screen">
        <!-- <div class="w-full bg-gradient-radial from-cyan-300 to-sky-600 relative"> -->
        <!-- <h1 class="block absolute top-1/2 left-1/2 -translate-x-28 -translate-y-24 text-9xl text-center text-white font-latobold">
                Bot <span class="block text-4xl">teach me</span><span class="block text-4xl">Личный кабинет</span>
            </h1> -->

        <div class="w-full bg-sky-100 relative">
            <div>ПРИВЕТ!</div>

            <!-- <div>{{ telegram?.WebAppInitData }}</div> -->
            <!-- <div>{{ telegram?.initDataUnsafe }}</div> -->

            <div class="flex flex-col space-y-4 p-8" v-if="webapp.student">
                <div class="text-xl">{{ moment().diff(moment(webapp.student.create_at, "YYYY-MM-DD"), "days") }} д.</div>

                <div class="text-xl">ЖИЗНИ: {{ webapp.student.life }}</div>
                <div class="text-xl">ЭНЕРГИЯ: {{ webapp.student.energy }}</div>
                <div class="text-xl">БАЛЛЫ: {{ webapp.student.point }}</div>
                <div class="text-xl">ДНИ: {{ webapp.student.day }}</div>
                <div class="text-xl">ДРУЗЬЯ: {{ webapp.student.friend }}</div>
            </div>

            <!-- {{ telegram?.MainButton }} -->

            <!-- <template v-if="studentModules?.length > 0">
                <div class="grid grid-cols-6 gap-2">
                    <div
                        v-for="mod in studentModules"
                        :key="mod.id"
                        class="bg-slate-50 rounded h-20 flex cursor-pointer justify-center items-center"
                        @click="viewModuleDz(mod.id)"
                    >
                        <h3 class="text-center text-lg group-hover:text-sky-500 transition">{{ mod.name }}</h3>
                    </div>
                </div>
            </template>

            <template v-if="dzs?.length > 0">
                <div class="grid grid-cols-4 gap-4">
                    <div v-for="dz in dzs" :key="dz.id" class="cursor-pointer justify-center items-center" @click="viewDz(dz.id)">
                        <h3 class="text-center text-lg group-hover:text-sky-500 transition">{{ dz.name }}</h3>
                    </div>
                </div>
            </template> -->

            <p class="w-full absolute bottom-8 text-white text-xs text-center mt-24 max-l:hidden">© 2019 - 2024</p>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";
import { useMessageStore } from "@/store/message";
import moment from "moment";

export default {
    name: "LkPage",

    data() {
        return {
            moment: moment,
            telegram: null,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup", "setSettings", "setView"]),
        ...mapState(useBotStore, ["bot", "webapp", "getBot", "sendApp"]),
        ...mapState(useMessageStore, ["messages", "message"]),

        student() {
            return this.meStudents.filter((item) => item.bot_id == this.bot.id);
        },
    },

    methods: {
        initTelegram() {
            this.telegram = window.Telegram.WebApp;
            this.sendApp({ bot_id: 141, fid: this.telegram.initDataUnsafe.user.id });
        },
    },

    mounted() {
        if (window && window?.Telegram && window.Telegram?.WebApp) {
            // Telegram WebApp API is already present
            this.initTelegram();
        } else {
            // Load the Telegram WebApp script dynamically
            const scriptElement = document.createElement("script");
            scriptElement.src = "https://telegram.org/js/telegram-web-app.js";
            scriptElement.async = true;

            scriptElement.onload = () => {
                if (window.Telegram?.WebApp) {
                    // Now that the script is loaded, initialize the Telegram object
                    this.initTelegram();
                }
            };

            const firstChild = document.head.firstChild;
            document.head.insertBefore(scriptElement, firstChild);
            this.sendApp("addScript");
        }
    },
};
</script>
