import API from "../../services/api";
import router from "@/services/router";

export default {
    state: {
        timersAll: [],
        timersF: [],
        timer: null,
    },

    actions: {
        getTimers(ctx) {
            ctx.dispatch("loadBot");
            // console.log("getTimers bot", ctx.rootState.bot.bot.id);
            if (!ctx.rootState.bot.bot.id) {
                router.push("/bot");
            }

            API.GET("api/timer/all", { bot_id: ctx.rootState.bot.bot.id }).then((res) => {
                console.log("getTimers", res.data.timers);

                if (res.data.groups) ctx.commit("setGroups", res.data.groups);
                if (res.data.types) ctx.commit("setTypes", res.data.types);
                if (res.data.modules) ctx.commit("setModules", res.data.modules);
                if (res.data.segments) ctx.commit("setSegments", res.data.segments);

                if (res.data.timers?.length > 0) {
                    res.data.timers.push({ status: 100, name: "таймер" });
                    ctx.commit("setTimers", res.data.timers);

                    ctx.dispatch("showAll");
                }
            });
        },

        // покажем выбранное (localStorage) после перезагрузки
        showAll(ctx) {
            // покажем выбранное сообщение
            let timer = JSON.parse(localStorage.getItem("timer"));
            if (timer) {
                ctx.commit("setTimer", timer);
            }
        },

        // СООБЩЕНИЯ

        // выбрать сообщение
        pickTimer(ctx, timer) {
            // console.log("pickTimer", timer);
            ctx.commit("setTimer", timer);
        },

        addTimer(ctx) {
            console.log("addTimer");

            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);

            API.POST("api/timer/add", formData).then((res) => {
                console.log("addTimer", res.data);

                if (res.data.alert) ctx.commit("setAlert", res.data.alert);

                if (res.data.timers?.length > 0) {
                    res.data.timers.push({ status: 100, name: "настройка" });
                    ctx.commit("setTimers", res.data.timers);
                }
            });
        },

        sortTimer(ctx) {
            // console.log("sortTimer");
            let sort = [];
            ctx.state.timersF.forEach((m) => sort.push(m.id));

            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("sort", sort);

            API.POST("api/timer/sort", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);

                if (res.data.timers?.length > 0) {
                    res.data.timers.push({ status: 100, name: "сообщение" });
                    ctx.commit("setTimers", res.data.timers);
                }
            });
        },

        saveTimer(ctx, timer) {
            console.log("setTimer", timer);
            ctx.commit("setTimer", timer);

            let formData = new FormData();
            Object.keys(timer).forEach(function (key) {
                formData.append(key, this[key]);
            }, timer);

            API.POST("api/timer/save", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);

                ctx.state.timers.forEach((item, i) => {
                    if (item.id === timer.id) {
                        ctx.state.timers[i] = timer;
                        return;
                    }
                });

                ctx.state.timersF.forEach((item, i) => {
                    if (item.id === timer.id) {
                        ctx.state.timersF[i] = timer;
                        return;
                    }
                });

                ctx.commit("setTimers", ctx.state.timers);
                ctx.commit("setTimersF", ctx.state.timersF);
            });
        },

        delTimer(ctx, m) {
            let s = ctx.state.timers.filter((item) => item.id !== m.id);
            ctx.commit("setTimers", s);

            let sF = ctx.state.timersF.filter((item) => item.id !== m.id);
            ctx.commit("setTimersF", sF);

            ctx.commit("setTimer", null);

            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("id", m.id);

            API.POST("api/timer/delete", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },

        pickTimerParam(ctx, param) {
            if (!ctx.state.timer?.id) {
                return;
            }

            let m = ctx.state.timer;

            if (ctx.rootState.s.popup == "groups") m.group_id = param.id;
            if (ctx.rootState.s.popup == "types") m.type_id = param.id;
            if (ctx.rootState.s.popup == "modules") m.module_id = param.id;
            // if (ctx.rootState.s.popup == "segments") m.segment_id = param.id;

            ctx.commit("setTimer", m);

            let formData = new FormData();
            formData.append("id", m.id);
            formData.append("param_id", param.id);
            formData.append("type", ctx.rootState.s.popup);

            API.POST("api/timer/set-param", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);

                ctx.state.timers.forEach((item, i) => {
                    if (item.id === m.id) {
                        ctx.state.timers[i] = m;
                        return;
                    }
                });
                ctx.commit("setTimers", ctx.state.timers);

                ctx.state.timersF.forEach((item, i) => {
                    if (item.id === m.id) {
                        ctx.state.timersF[i] = m;
                        return;
                    }
                });
                ctx.commit("setTimersF", ctx.state.timersF);
            });
        },
    },
};
