import API from "../../services/api";

export default {
    state: {
        groups: [],
        groupsF: [],
        group: null,
        path: "",
    },

    mutations: {
        setGroups: (state, groups) => {
            state.groups = groups;
        },
        setGroupsF: (state, groups) => {
            if (state.group && state.group.parent > 0) {
                groups.unshift({ status: 99, name: "назад" });
            }
            groups.push({ status: 100, name: "папка" });
            state.groupsF = groups;
        },
        setGroup: (state, group) => {
            state.group = group;
            localStorage.setItem("gid", group?.id);
        },

        setPath: (state, path) => {
            state.path = path;
            if (path) {
                localStorage.setItem("path", path);
            } else {
                localStorage.removeItem("path");
            }
        },
    },

    actions: {
        loadPath(ctx) {
            let path = localStorage.getItem("path");
            if (path) {
                ctx.commit("setPath", path);
            } else {
                ctx.commit("setPath", "Бот");
            }
        },

        loadGroup(ctx) {
            let groups = [];
            let gid = parseInt(localStorage.getItem("gid"));
            if (gid && gid != "undefined") {
                groups = ctx.state.groups.filter((item) => item.id === parseInt(gid));
            } else {
                groups = ctx.state.groups.filter((item) => item.parent === 0);
            }
            ctx.commit("setGroup", groups[0]);
        },

        generatePath(ctx) {
            function prevStep(group, path) {
                if (group.parent === 0) {
                    return "Бот" + path;
                } else {
                    path = "/" + group.name + path;
                    let g = ctx.state.groups.filter((item) => item.id == group.parent);
                    return prevStep(g[0], path);
                }
            }
            ctx.commit("setPath", prevStep(ctx.state.group, ""));
        },

        // ГРУППЫ

        addGroup(ctx, parent) {
            let formData = new FormData();
            formData.append("bot_id", ctx.rootState.bot.bot.id);
            formData.append("parent", parent);

            API.POST("api/group/add", formData).then((res) => {
                // console.log("addGroup res ", res.data.group);
                // console.log("addGroup groups ", ctx.state.groups);
                // let gs = ctx.state.groups.push(res.data.group);

                // console.log("addGroup gsgsgsgsgs ", gs);
                // ctx.commit("setGroup", res.data.group);
                ctx.commit("setGroups", res.data.groups);
                ctx.dispatch("pickGroup", res.data.group);
            });
        },

        increment(ctx) {
            ctx.state.group.count += 1;
            ctx.commit("setGroup", ctx.state.group);
        },

        decrement(ctx) {
            ctx.state.group.count -= 1;
            ctx.commit("setGroup", ctx.state.group);
        },

        // назад
        backGroup(ctx, id) {
            let group = ctx.state.groups.filter((item) => item.id === id);
            ctx.dispatch("pickGroup", group[0]);
            ctx.dispatch("generatePath");
        },

        setGroupName(ctx, name) {
            let formData = new FormData();
            formData.append("group_id", ctx.state.group.id);
            formData.append("name", name);
            API.POST("api/group/set-name", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },

        sortGroup(ctx) {
            let sort = [];
            ctx.state.groupsF.forEach((g) => {
                if (g.id && g.id > 0) {
                    return sort.push(g.id);
                }
            });

            let formData = new FormData();
            formData.append("parent", ctx.state.group.id);
            formData.append("sort", sort);

            API.POST("api/group/sort", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },

        delGroup(ctx, g) {
            let formData = new FormData();
            formData.append("id", g.id);

            let groups = ctx.state.groups.filter((item) => item != g);
            ctx.commit("setGroups", groups);

            let groupsF = ctx.state.groupsF.filter((item) => item != g);
            ctx.commit("setGroupsF", groupsF);

            let parent = ctx.state.groups.filter((item) => item.id == g.parent);
            ctx.dispatch("pickGroup", parent[0]);

            API.POST("api/group/delete", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },
    },

    getters: {
        allgroups(state) {
            return state.groups;
        },
        groups(state) {
            return state.groupsF;
        },
        group(state) {
            return state.group;
        },
        path(state) {
            return state.path;
        },
    },
};
