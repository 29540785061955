<template>
    <div class="mb-4">
        <h3 class="text-lg text-center">Генератор</h3>
        <p>
            Для генерации заданий, вставьте текст, разделенный запятыми: "Вопрос", "Ответ 1", "Ответ 2", "Ответ 3", "Ответ 4", "Номер правильного
            ответа", "Пояснение"
        </p>
    </div>

    <div class="flex h-1/3 items-center justify-between">
        <textarea class="w-full h-full bg-slate-100" v-model="csv" cols="30" rows="15"></textarea>
    </div>

    <div class="h-1/2 mt-4 overflow-y-scroll">
        <div v-for="item in preview" :key="item" class="w-full bg-slate-100 text-sm p-2 mb-1">
            <div class="mb-1 grid grid-cols-6">
                <span class="text-xs text-slate-400">ВОПРОС</span>
                <h2 class="col-span-5">{{ item.message }}</h2>
            </div>

            <div class="mb-1 grid grid-cols-6">
                <span class="text-xs text-slate-400">ВАРИАНТЫ</span>
                <div class="col-span-5 flex gap-2 mb-1">
                    <div
                        class="px-2 py-1 bg-pink-200 rounded"
                        :class="{ '!bg-teal-200': index == item.answer_id - 1 }"
                        v-for="(answer, index) in item.btns"
                        :key="index"
                    >
                        {{ answer }}
                    </div>
                </div>
            </div>
            <div class="mb-1 grid grid-cols-6">
                <span class="text-xs text-slate-400">ПОЯСНЕНИЕ</span>
                <p class="col-span-5">{{ item?.answy }}</p>
            </div>
        </div>
    </div>

    <div class="flex justify-end items-center w-full">
        <div class="v-btn btn-green rounded bg-teal-400 text-white cursor-pointer" @click="GenerateCSV(preview)">Сгенерировать</div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useBotStore } from "@/store/bot";
import { useMessageStore } from "@/store/message";

export default {
    name: "MessageGenerate",

    data() {
        return {
            csv: "",
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useBotStore, ["bot", "files", "getFiles", "uploadImg"]),
        ...mapState(useMessageStore, ["message", "saveMessage", "GenerateCSV"]),

        preview() {
            if (!this.csv || this.csv.length < 1) return null;

            const lines = this.csv.split("\n");

            let preview = [];

            lines.forEach((line) => {
                const items = line.split(";");

                if (items.length >= 3) {
                    preview.push({
                        message: items[0],
                        btns: [items[1], items[2], items[3], items[4]],
                        answer_id: items[5],
                        answy: items[6],
                    });
                }
            });

            return preview;
        },
    },

    mounted() {},
};
</script>
