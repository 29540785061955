import axios from "axios";
import authHeader from "./header";
// const API = "http://localhost/";
const API = "https://yii.botteach.ru/";
class UserService {
    GET(url, data) {
        return axios.get(API + url, {
            params: data,
            headers: authHeader(),
            // onDownloadProgress: function (e) {
            //     console.log("progressEvent", e);
            // },
        });
    }
    POST(url, formData) {
        return axios.post(API + url, formData, { headers: authHeader() });
    }
}
export default new UserService();
