<template>
    <svg
        class="fill-stroke transition duration-300 hover:text-red-500 cursor-pointer"
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>
    </svg>
</template>

<script>
export default {
    name: "IconClose",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
