import { defineStore } from "pinia";
import API from "../services/api";
// import router from "@/services/router";
import { useMainStore as mainStore } from "./main";
import { useBotStore as botStore } from "./bot";
import { useGroupStore as groupStore } from "./group";

export const useMessageStore = defineStore("message", {
    state: () => ({
        messages: [],
        messagesF: [],
        message: {},
        btns: [],
        btn: [],
        targetList: [],
        codeList: [],
        allCodes: [
            { code: "cMenu", name: "Меню" },
            { code: "cInfinity", name: "Тренировка" },
            { code: "cLesson", name: "Все уроки" },
            { code: "cDz", name: "Задания" },
        ],
        nextMessageId: null,

        types: [],
        modules: [],
        variables: [],
        themes: [],
    }),

    actions: {
        async sortMessage() {
            let sort = [];
            this.messagesF.forEach((m) => sort.push(m.id));

            let formData = new FormData();
            formData.append("bot_id", groupStore().group.bot_id);
            formData.append("group_id", groupStore().group.id);
            formData.append("sort", sort);

            const res = await API.POST("message/message/sort", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.setMessagesF(res.data.messages);
        },

        async addMessage(message = false) {
            let formData = new FormData();
            formData.append("bot_id", groupStore().group.bot_id);
            formData.append("group_id", groupStore().group.id);
            if (message) {
                formData.append("message_id", message.id);
                formData.append("sort", message.sort);

                // Пересортируем сообщения справа в полном списке
                this.messages.forEach((item) => {
                    if (item.group_id == groupStore().group.id && item.sort >= message.sort) {
                        item.sort++;
                        return;
                    }
                });
            } else {
                if (this.messagesF?.length > 0) {
                    // -2 потому что последнее это "+", нужно предпоследнее
                    formData.append("sort", this.messagesF[this.messagesF.length - 2].sort + 1);
                } else {
                    formData.append("sort", 1);
                }
            }
            console.log("sort", formData.sort);

            const res = await API.POST("message/message/add", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            if (res.data.message) {
                this.messages.push(res.data.message);

                this.filterMessages(groupStore().group.id);
                this.pickMessage(res.data.message);
            }
            groupStore().increment();
        },

        async saveMessage(m) {
            this.message = m;
            localStorage.setItem("message", JSON.stringify(this.message));

            let formData = new FormData();
            Object.keys(m).forEach(function (key) {
                formData.append(key, this[key]);
            }, m);

            const res = await API.POST("message/message/save", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            this.message = res.data.message;

            this.messages.forEach((item, i) => {
                if (item.id === this.message.id) this.messages[i] = this.message;
            });

            this.messagesF.forEach((item, i) => {
                if (item.id === this.message.id) this.messagesF[i] = this.message;
            });
        },

        // добавить в сообщение Goto - id следующего сообщения
        async setGoto(id) {
            this.message.goto = id;

            let formData = new FormData();
            formData.append("bot_id", this.message.bot_id);
            formData.append("id", this.message.id);
            formData.append("goto", id);

            const res = await API.POST("message/message/set-goto", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
        },

        async delMessage(m) {
            this.messages = this.messages.filter((item) => item.id !== m.id);
            this.filterMessages(m.group_id);

            this.message = null;
            this.btns = null;

            let formData = new FormData();
            formData.append("bot_id", m.bot_id);
            formData.append("group_id", m.group_id);
            formData.append("id", m.id);

            const res = await API.POST("message/message/delete", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            groupStore().decrement();
        },

        setMessagesF(messages) {
            let plus = messages.filter((item) => parseInt(item.status) == 100);
            if (messages?.length > 0 && plus?.length == 0) {
                messages.push({ status: 100, name: "сообщение" });
            }
            this.messagesF = messages;
        },

        filterMessages(group_id) {
            let ms = this.messages.filter((item) => item.group_id == group_id);
            ms.sort((a, b) => parseInt(a.sort) - parseInt(b.sort));
            this.setMessagesF(ms);
            return ms.length;
        },

        // drag&grop переместить сообщение в группу
        putMessageToGroup(e) {
            if (!e) return;

            console.log("eeee", e.originalEvent);

            // let group_id = 0;
            // e.originalEvent.path.forEach((element) => {
            //     if (element?.id?.slice(0, 1) == "g" && parseInt(element?.id?.slice(1)) > 0) {
            //         group_id = parseInt(element?.id?.slice(1));
            //     }
            // });

            // let m_id = e.item?.id?.slice(1);
            // if (group_id && parseInt(group_id) > 0 && m_id && parseInt(m_id) > 0) {
            //     let formData = new FormData();
            //     formData.append("group_id", group_id);
            //     formData.append("id", e.item?.id?.slice(1));

            //     API.POST("message/message/to-group", formData).then((res) => {
            //         if (res.data.alert) mainStore().setAlert(res.data.alert);

            //         let i = this.messages.findIndex(function (item) {
            //             return parseInt(item.id) === parseInt(m_id);
            //         });
            //         this.messages[i].gid = group_id;

            //         this.messagesF = this.messagesF.filter((item) => parseInt(item.id) !== parseInt(m_id));
            //     });
            // }
        },

        pickMessage(message) {
            this.message = message;
            localStorage.setItem("message", JSON.stringify(message));
            localStorage.setItem("message_id", message.id);

            if (message?.btns) {
                this.btns = JSON.parse(message?.btns);
            } else {
                this.btns = null;
            }
        },

        hoverNext(id = false) {
            if (id) {
                this.nextMessageId = parseInt(id);
            } else {
                this.nextMessageId = null;
            }
        },

        pickBtn(btn) {
            this.btn = btn;
            mainStore().setPopup("btn");
        },

        addBtn(row = 0) {
            if (this.btns) {
                if (this.btns[row]) {
                    this.btns[row].push(["КНОПКА", "", "primary"]);
                } else {
                    this.btns[row] = [["КНОПКА", "", "primary"]];
                }
            } else {
                this.btns = [[["КНОПКА", "", "primary"]]];
            }

            this.saveBtns(this.btns);
        },

        async saveBtns(btns) {
            btns = btns.filter((row) => row.length > 0);
            this.btns = btns;

            this.message.btns = JSON.stringify(btns);

            let formData = new FormData();
            formData.append("bot_id", this.message.bot_id);
            formData.append("id", this.message.id);
            formData.append("btns", JSON.stringify(btns));

            const res = await API.POST("message/message/save-btns", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
        },

        delBtn(btn) {
            let newbtns = this.btns.map((row) => {
                return row.filter((b) => b != btn);
            });

            this.saveBtns(newbtns);
        },

        // список сообщений для выбора "куда веден кнопка"
        targetMessage(findme) {
            let target = [];
            let codes = [];
            if (findme != "" && findme != undefined) {
                target = this.messages.filter((item) => item.name.toLowerCase().indexOf(findme.toLowerCase()) !== -1);
                target = target.filter((item) => item.id !== this.message.id);
                this.targetList = target.slice(0, 3);

                codes = this.allCodes.filter((item) => item.name.toLowerCase().indexOf(findme.toLowerCase()) !== -1);
                this.codeList = codes.slice(0, 3);
            }
        },

        async sendMe(net) {
            let formData = new FormData();
            formData.append("bot_id", botStore().bot.id);
            formData.append("message_id", this.message.id);
            formData.append("net", net);

            const res = await API.POST("send/send/me", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
        },

        // отправить студенту вручную
        async send(data) {
            let formData = new FormData();
            formData.append("bot_id", botStore().bot.id);
            formData.append("message_id", this.message.id);
            formData.append("net", data.net);
            formData.append("student_id", data.student_id);

            const res = await API.POST("send/send/send", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
        },

        // ПАРАМЕТРЫ

        // ПАРМЕТРЫ В СООБЩЕНИИ
        setParams(data) {
            if (data.modules) this.modules = data.modules;
            if (data.types) this.types = data.types;
            if (data.variables) this.variables = data.variables;
            if (data.themes) this.themes = data.themes;
            if (data.segments) this.segments = data.segments;
        },

        async addParamData(name) {
            let formData = new FormData();
            formData.append("bot_id", botStore().bot.id);
            formData.append("name", name);
            formData.append("type", mainStore().s.popup);

            const res = await API.POST("message/param/add", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.setParams(res.data);
        },

        async sortParamData() {
            let sort = [];
            this.modules.forEach((m) => sort.push(m.id));
            console.log("sort", sort);

            let formData = new FormData();
            formData.append("bot_id", mainStore().group.group.bot_id);
            formData.append("sort", sort);

            const res = await API.POST("message/param/sort", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.setParams(res.data);
        },

        async pickParamData(param) {
            if (!this.message?.id) return;

            let type = mainStore().s.popup;

            if (type == "modules") this.message.module_id = param.id;
            if (type == "types") this.message.type_id = param.id;
            if (type == "variables") this.message.variable_id = param.id;
            if (type == "themes") this.message.theme_id = param.id;
            // if (type == "segments") this.message.segment_id = param.id;

            localStorage.setItem("message", JSON.stringify(this.message));

            let formData = new FormData();
            formData.append("bot_id", this.message.bot_id);
            formData.append("message_id", this.message.id);
            formData.append("param_id", param.id);
            formData.append("type", type);

            mainStore().closePopup();

            const res = await API.POST("message/param/set", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            this.messages.forEach((item, i) => {
                if (item.id === this.message.id) this.messages[i] = this.message;
            });

            this.messagesF.forEach((item, i) => {
                if (item.id === this.message.id) this.messagesF[i] = this.message;
            });
        },

        async clearParam(type) {
            if (!this.message?.id) return;

            if (type == "module_id") this.message.module_id = 0;
            if (type == "variable_id") this.message.variable_id = 0;
            if (type == "theme_id") this.message.theme_id = 0;
            // if (type == "segment_id")this.message.segment_id = 0;

            let formData = new FormData();
            formData.append("id", this.message.id);
            formData.append("type", type);

            const res = await API.POST("message/param/clear", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            this.messages.forEach((item, i) => {
                if (item.id === this.message.id) this.messages[i] = this.message;
            });

            this.messagesF.forEach((item, i) => {
                if (item.id === this.message.id) this.messagesF[i] = this.message;
            });
        },

        async delParamData(id) {
            let formData = new FormData();
            formData.append("bot_id", botStore().bot.id);
            formData.append("id", id);
            formData.append("type", mainStore().s.popup);

            const res = await API.POST("message/param/del", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.setParams(res.data);
        },

        async GenerateCSV(text) {
            let formData = new FormData();
            formData.append("bot_id", botStore().bot.id);
            formData.append("group_id", groupStore().group.id);
            formData.append("text", JSON.stringify(text));

            const res = await API.POST("message/message/generate", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            console.log("GenerateCSV", res.data);

            if (res.data.messages) {
                this.messages = res.data.messages;
                mainStore().closePopup();
                groupStore().pickGroup(groupStore().group);
            }
        },
    },
});
