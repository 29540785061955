import { defineStore } from "pinia";
import API from "../services/api";
import router from "@/services/router";
import { useMainStore as mainStore } from "./main";
import { useGroupStore as groupStore } from "./group";
import { useMessageStore as messageStore } from "./message";

export const useBotStore = defineStore("bot", {
    state: () => ({
        bots: [],
        bot: {},
        teams: [],
        files: [],
        meStudents: [],

        webapp: [],
    }),

    actions: {
        // данные из TELEGRAM MINI WEB APP
        async sendApp(data) {
            let formData = new FormData();
            formData.append("bot_id", data.bot_id);
            formData.append("fid", data.fid);
            const res = await API.POST("bot/bot/app", formData);
            this.webapp = res.data;
        },

        async getBots() {
            this.bot = null;
            localStorage.removeItem("bot");
            groupStore().group = null;
            localStorage.removeItem("gid");
            messageStore().message = null;
            localStorage.removeItem("message");

            const res = await API.GET("bot/bot/list");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.bots = res.data.bots;
        },

        async getBot() {
            const res = await API.GET("bot/bot/one", { id: this.bot.id });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.bot = res.data.bot;
            this.teams = res.data.teams;

            groupStore().groupsAll = res.data.groups;
            groupStore().setGroups(res.data.groups);
            messageStore().messages = res.data.messages;
            messageStore().messagesF = res.data.messages;

            messageStore().types = res.data.types;
            messageStore().modules = res.data.modules;
            messageStore().variables = res.data.variables;
            messageStore().themes = res.data.themes;
            messageStore().segments = res.data.segments;

            groupStore().loadPath();
            groupStore().loadGroup();

            groupStore().pickGroup(groupStore().group);

            // покажем выбранное сообщение
            let message_id = localStorage.getItem("message_id");
            if (parseInt(message_id)) messageStore().pickMessage(messageStore().messages.filter((m) => m.id == message_id)[0]);
        },

        async botInfo() {
            const res = await API.GET("bot/bot/info", { id: this.bot.id });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
        },

        pickBot(bot) {
            this.bot = bot;
            localStorage.setItem("bot", JSON.stringify(this.bot));
            router.push("/bot");
        },

        async addBot(data) {
            let formData = new FormData();
            formData.append("type", 2);
            formData.append("name", data.name);
            formData.append("tl_token", data.tl_token);

            const res = await API.POST("bot/bot/add", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            if (res.data.bots) this.bots = res.data.bots;
            if (res.data.bot_id) this.pickBot(res.data.bot);
            if (res.data.meStudents) this.meStudents = res.data.meStudents;
        },

        async saveBot(bot) {
            let formData = new FormData();
            formData.append("id", bot.id);
            formData.append("name", bot.name);
            formData.append("type", bot.type);
            formData.append("tl", bot.tl);
            formData.append("tl_token", bot.tl_token);
            formData.append("tl_link", bot.tl_link);
            formData.append("status", bot.status);

            const res = await API.POST("bot/bot/save", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            if (res.data.bots) this.bots = res.data.bots;
            if (res.data.bot_id) this.bot = res.data.bot;
        },

        // viewBot(bot) {
        //     if (bot.id != ctx.state.bot.id) {
        //         ctx.commit("setPath", null);
        //         ctx.commit("setMessages", null);
        //         ctx.commit("setMessage", null);
        //         ctx.commit("setGroups", null);
        //         ctx.commit("setGroup", null);
        //     }
        //     ctx.commit("setBot", bot);
        //     router.push("/bot");
        // },

        async delBot(bot) {
            let formData = new FormData();
            formData.append("id", bot.id);

            await API.POST("bot/bot/delete", formData);
            this.strategies = this.strategies.filter((item) => item.id !== bot.id);
        },

        async getFiles() {
            const res = await API.GET("bot/file/bot", { id: this.bot.id });
            this.files = res.data.files;
        },

        async uploadImg(data) {
            console.log("uploadImg", data);
            let formData = new FormData();

            // for (var i = 0; i < data.e.target.files.length; i++) {
            let file = data.e.target.files[0];
            console.log("file", file);

            let ext = "не определилось";
            let parts = file.name.split(".");
            if (parts.length > 1) {
                ext = parts.pop();
            }

            if (!["jpeg", "jpg", "png"].includes(ext)) {
                mainStore().setAlert({ msg: "Неправильный формат. Загрузите .jpg .png", type: "error" });
                // ctx.state.loadForm = true;
                return;
            }

            formData.append("picture", file);
            formData.append("bot_id", this.bot.id);

            console.log("API", formData);

            const res = await API.POST("bot/file/upload-picture", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    this.progressBar = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                },
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);

            this.files.push(res.data.file);
            this.loadForm = true;

            // API.POST("bot/file/upload", formData, {
            //     headers: { "Content-Type": "multipart/form-data" },
            //     onUploadProgress: function (progressEvent) {
            //         ctx.state.progressBar = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            //     }.bind(ctx),
            // })
            //     .then((res) => {
            //         console.log(res.data);
            //         ctx.state.files.push(res.data.file);
            //         ctx.state.loadForm = true;
            //     })
            //     .catch((error) => console.log(error));
        },
    },
});
